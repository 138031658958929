import React, { useEffect, useState } from 'react';
import { Button, message, Modal, Popconfirm, Tag } from 'antd';
import { EditOutlined, MinusCircleOutlined } from '@ant-design/icons';
import styles from './Header.module.css';
import { IElements, SubItem } from '../../types/types';
import Table, { ColumnsType } from 'antd/lib/table';
import { UpdateModal } from '../Modal/UpdateModal/UpdateModal';
import { initItem } from '../../utils/const';
import { headerMethod } from '../../types/types'
import { deleteHeader, getHeader } from '../../store/adminAction';
import { useAppDispatch } from '../../hooks/useAppDispatch';

export const Header: React.FC = () => {
  const [data, setData] = useState<IElements[]>([]);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [currItem, setCurrItem] = useState<IElements>(initItem);
  const [currMethod, setCurrMethod] = useState<headerMethod>(headerMethod.UPDATE);
  const dispatch = useAppDispatch();

  const superAdmin = JSON.parse(localStorage.getItem("superAdmin") || "{}");

  const cancel = () => {
    message.error('Click on No');
  };

  const columns: ColumnsType<IElements> = [
    ...Object.keys(initItem).map(item => {
      if (item === 'menu') {
        return {
          title: 'Sub items',
          dataIndex: item,
          key: item,
          render: (menu: SubItem[]) => menu && (
            <>
              {menu.map((set) => (
                <Tag key={set.title}>
                  {set.title}
                </Tag>
              ))}
            </>
          )
        }
      } else if (item === 'sets') {
        return {
          title: item,
          dataIndex: item,
          key: item,
          render: (sets: string[]) => sets && (
            <>
              {sets.map((set) => (
                <Tag key={set}>
                  {set}
                </Tag>
              ))}
            </>
          )
        }
      }
      return {
        title: item,
        dataIndex: item,
        key: item,
      }
    }),
    {
      title: '',
      dataIndex: 'functional',
      render: (_: any, record: IElements, rowIndex) => {
        return (
          <div className={styles.edit_delete_column}>
            <Button
              className={styles.edit_button}
              type="default"
              onClick={() => {
                setCurrMethod(headerMethod.UPDATE)
                showModal(rowIndex)
              }}
            >
              <EditOutlined />
            </Button>
            <Popconfirm
              title="Delete the item?"
              onConfirm={() => { removeField(rowIndex) }}
              onCancel={() => cancel}
              okText="Yes"
              cancelText="No"
            >
              <Button
                className={styles.delete_button}
                danger={true}
                type="primary"
              >
                <MinusCircleOutlined />
              </Button>
            </Popconfirm>
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    if (!isModalOpen) {
      dispatch(getHeader()).then((response: any) => {
        setData(response.payload)
      })
    }
  }, [isModalOpen])

  const removeField = (index: number): void => {
    dispatch(deleteHeader({ ...superAdmin, id: data[index].id }))
    setData((prev) => prev.filter((_, idx) => idx !== index));
  };

  const showModal = (order?: number) => {
    if (order !== undefined) {
      setCurrItem(data[order])
    } else {
      setCurrItem(initItem)
    }
    setIsModalOpen(true);
  };

  return (
    <>
      <h1 className={styles.title}>Header Editor</h1>
      <div className={styles.header}>
        <Table columns={columns} dataSource={data} size="middle" />
        <div className={styles.add_button}>
          <Button
            type="dashed"
            className={styles.add_button}
            onClick={() => {
              setCurrMethod(headerMethod.ADD)
              showModal()
            }}
          >
            Add item
          </Button>
        </div>
      </div>
      <Modal
        width={700}
        title="Header Item Modal"
        visible={isModalOpen}
        onCancel={() => setIsModalOpen(false)}
        footer={null}>
        <UpdateModal item={currItem} method={currMethod} close={() => setIsModalOpen(false)} />
      </Modal>
    </>
  )
};
