import React from "react";
import {Col, Tabs} from 'antd';
import { BanModal } from "./BanModal";
import { IBanModal } from "../../../types/types";
import { BanCountry } from "./BanCountry";

export const BanPanel: React.FC<IBanModal> = ({ closeModal }: IBanModal) => {
  
  return (
    <div>
      <Tabs centered defaultActiveKey={"1"}>
        <Tabs.TabPane tab="По номеру" key="1">
          <Col>
            <BanModal closeModal={closeModal}/>
          </Col>
        </Tabs.TabPane>
        <Tabs.TabPane tab="По стране" key="2">
          <Col>
            <BanCountry closeModal={closeModal}/>
          </Col>
        </Tabs.TabPane>
      </Tabs>
    </div>
  );
};
