import React, { FC } from 'react';
import styles from "./CreateUsers.module.css";
import { Button, Form, FormProps, InputNumber } from "antd";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import { createFakeUsers } from "../../store/adminAction";
import { ISuperAdmin } from "../../types/types";
import { useTypedSelector } from "../../hooks/useTypedSelector";

type FieldType = {
  number_users: number;
};

export const CreateUsers: FC = () => {
  const { createdUsers } = useTypedSelector((state) => state.adminReducer)
  const dispatch = useAppDispatch();
  const superAdmin: ISuperAdmin = JSON.parse(localStorage.getItem("superAdmin") || "{}");
  const onFinish: FormProps<FieldType>['onFinish'] = (values) => {
    const data = {
      login: superAdmin.login,
      password: superAdmin.password,
      number_users: values.number_users
    }
    dispatch(createFakeUsers(data))
  };

  const onFinishFailed: FormProps<FieldType>['onFinishFailed'] = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  const saveUsers = (): void => {
    const blob = new Blob([JSON.stringify(createdUsers, null, 2)], { type: 'application/json' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = 'users.json';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  };

  return (
    <div className={styles.block}>
      <h1 className={styles.title}>Создание пользователей</h1>
      <Form
        name="basic"
        initialValues={{ remember: true }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <Form.Item<FieldType>
          label="Количетво юзеров"
          name="number_users"
          rules={[{ required: true, message: 'Пожалуйста укажите количество создаваемых пользователей!' }]}
        >
          <InputNumber min={1} className={styles.input}/>
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit" className={styles.btn}>
            Добавить пользователей
          </Button>
        </Form.Item>
      </Form>
      {!!createdUsers.length && (
        <>
          <h2 className={styles.titleWarning}>
            Обязательно сохраните данные, потому что доступа к ним больше не будет.
          </h2>
          <Button
            type="primary"
            className={styles.btnSave}
            onClick={saveUsers}
          >
            Сохранить всех пользователей
          </Button>
          <div className={styles.users}>
            {createdUsers.map((item) => (
              <div className={styles.user} key={item.password}>
                <p>Логин: {item.login}</p>
                <p>Пароль: {item.password}</p>
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  );
};
