import axios from "axios";
import {
    IAddOauthAppData,
    IBlock,
    IBlockList,
    ICodeBlock,
    ICreateFakeUsers,
    IDeleteOauthAppData,
    ISuperAdmin,
    IUpdateOauthAppData
} from "../types/types";

export const base_url: any = process.env.REACT_APP_ADMIN_BASE_URL

const endpoints = {
    getAllUsers: process.env.REACT_APP_ADMIN_GET_ALL_USERS,
    deactivateUser: process.env.REACT_APP_ADMIN_DEACTIVE_USER,
    deleteUser: process.env.REACT_APP_ADMIN_DELETE_USER,
    getUserToken: process.env.REACT_APP_ADMIN_GET_USER_TOKEN,
    set_user: process.env.REACT_APP_AUTH_SET_USER_DATA,
    getUserData: process.env.REACT_APP_AUTH_GET_USER_DATA,
    query_da_data_inn: process.env.REACT_APP_AUTH_POST_DA_DATA_QUERY_INN,
    query_da_data: process.env.REACT_APP_AUTH_POST_DA_DATA_QUERY,
    upload_file: process.env.REACT_APP_AUTH_UPLOAD_FILE,
    add_role: process.env.REACT_APP_AUTH_ADD_ROLE,
    delete_role: process.env.REACT_APP_AUTH_DELETE_ROLE,
    activateUser: process.env.REACT_APP_ADMIN_ACTIVE_USER,
    get_logs: process.env.REACT_APP_AUTH_GET_LOGS,
    update_pull: process.env.REACT_APP_ADMIN_UPDATE_PULL,
    get_list_words: process.env.REACT_APP_GET_LIST_WORDS,
    set_list_words: process.env.REACT_APP_SET_LIST_WORDS,
    get_header: process.env.REACT_APP_HEADER_GET_DATA,
    add_header: process.env.REACT_APP_HEADER_ADD_DATA,
    update_header: process.env.REACT_APP_HEADER_UPDATE_DATA,
    delete_header: process.env.REACT_APP_HEADER_DELETE_DATA,
    get_block: process.env.REACT_APP_BLOCK_LIST,
    unblock: process.env.REACT_APP_UNBLOCK,
    block: process.env.REACT_APP_BLOCK,
    updateNorm: process.env.REACT_APP_UPDATE_NORM,
    codeBlock: process.env.REACT_APP_CODE_BLOCK,
    get_code_block: process.env.REACT_APP_CODE_BLOCK_LIST,
    unblock_code: process.env.REACT_APP_UNBLOCK_CODE,
    get_oauth_apps: process.env.REACT_APP_GET_OAUTH_APPS,
    add_oauth_apps: process.env.REACT_APP_ADD_OAUTH_APPS,
    delete_oauth_app: process.env.REACT_APP_DELETE_OAUTH_APP,
    update_oauth_app: process.env.REACT_APP_UPDATE_OAUTH_APP,
    deletePersonalUser: process.env.REACT_APP_ADMIN_DELETE_PERSONAL_USER,
    restoreUser: process.env.REACT_APP_ADMIN_RESTORE_USER,
    createFakeUsers: process.env.REACT_APP_CREATE_FAKE_USERS,
}

export const base_image_url = process.env.REACT_APP_UNIONE_BASE_IMAGE_URL // я добавил 5 июля

const base_da_data_url: any = process.env.REACT_APP_AUTH_POST_DA_DATA

export default class ProfileService {

    static async $getLogs(data: string) {
        return await axios.post(base_url + endpoints.get_logs, data)
    }

    static async $getAllUsers(data: string) {
        return await axios.post(base_url + endpoints.getAllUsers, data)
    }

    static async $deactivateUser(data: string) {
        return await axios.post(base_url + endpoints.deactivateUser, data)
    }

    static async $deleteUser(data: string) {
        return await axios.post(base_url + endpoints.deleteUser, data)
    }

    static async $restoreUser(data: string) {
        return await axios.post(base_url + endpoints.restoreUser, data)
    }

    static async $getUserToken(data: string) {
        return await axios.post(base_url + endpoints.getUserToken, data)
    }

    static async $set_User(data: string) {
        return axios.post(base_url + endpoints.set_user, data)
    }

    static async $getUserData(token: string | number | null) {
        return axios.post(base_url + endpoints.getUserData, token)
    }

    static async $getUserInnData(data: string) {
        return await axios.post(base_da_data_url + endpoints.query_da_data_inn, data);
    }

    static async $getUserGeoData(data: string) {
        return await axios.post(base_da_data_url + endpoints.query_da_data, data);
    }

    static async $upload_File(data: any) {
        return await axios.post(base_url + endpoints.upload_file, data ,  {
            headers: { "Content-Type": "multipart/form-data" },
        })
    }

    static async $addRole(data: string) {
        return await axios.post(base_url + endpoints.add_role, data);
    }

    static async $deleteRole(data: string) {
        return await axios.post(base_url + endpoints.delete_role, data);
    }

    static async $activateUser(data: string) {
        return await axios.post(base_url + endpoints.activateUser, data)
    }

    static async $updatePull(data:string) {
        return await axios.post(base_url + endpoints.update_pull, data)
    }

    static async $get_list_words(data: string) {
        return await axios.post(base_url + endpoints.get_list_words, data)
    }

    static async $set_list_words(data: string) {
        return await axios.post(base_url + endpoints.set_list_words, data)
    }

    static async $getHeader() {
        return await axios.post(base_url + endpoints.get_header)
    }

    static async $getBlockList(data: IBlockList) {
        return await axios.post(base_url + endpoints.get_block, data)
    }

    static async $getCodeBlockList() {
        return await axios.post(base_url + endpoints.get_code_block, {number: 1})
    }

    static async $unblock(data: any) {
        return await axios.post(base_url + endpoints.unblock, data)
    }

    static async $unblockCode(data: ICodeBlock) {
        return await axios.post(base_url + endpoints.unblock_code, data)
    }

    static async $block(data: IBlock) {
        return await axios.post(base_url + endpoints.block, data)
    }

    static async $updateNorm(data: any) {
        return await axios.post(base_url + endpoints.updateNorm, data)
    }

    static async $codeBlock(data: any) {
        return await axios.post(base_url + endpoints.codeBlock, data)
    }

    static async $addHeader(data: any) {
        return await axios.post(base_url + endpoints.add_header, data)
    }

    static async $updateHeader(data: any) {
        return await axios.post(base_url + endpoints.update_header, data)
    }

    static async $deleteHeader(data: any) {
        return await axios.post(base_url + endpoints.delete_header, data)
    }

    static async $getOauthApps(data: ISuperAdmin) {
        return await axios.post(base_url + endpoints.get_oauth_apps, data)
    }

    static async $addOauthApp(data: IAddOauthAppData) {
        return await axios.post(base_url + endpoints.add_oauth_apps, data)
    }

    static async $deleteOauthApp(data: IDeleteOauthAppData) {
        return await axios.post(base_url + endpoints.delete_oauth_app, data)
    }

    static async $updateOauthApp(data: IUpdateOauthAppData) {
        return await axios.post(base_url + endpoints.update_oauth_app, data)
    }

    static async $deletePersonalUser(data: string) {
        return await axios.post(base_url + endpoints.deletePersonalUser, data)
    }

    static async $createFakeUsers(data: ICreateFakeUsers) {
        return await axios.post(base_url + endpoints.createFakeUsers, data)
    }
}
