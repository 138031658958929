import { Col, Radio } from "antd";
import { FC } from "react";
import { IRadioFilterProps } from "../../../types/types";

export const RadioFilter: FC<IRadioFilterProps> = ({ values, onChange }: IRadioFilterProps) => {
  return (
    <Radio.Group style={{ width: '100%', height: '20px' }} onChange={onChange}>
      {values.map((value, idx: number) =>
        <Col style={{height: '20px'}}>
          <Radio
            key={idx}
            value={value}
          >
            {value}
          </Radio>
        </Col>
      )}
    </Radio.Group>
  )
}
