import React, { FC, useEffect, useState } from 'react';
import { useAppDispatch } from '../../hooks/useAppDispatch';
import { deleteOauthApp, getOauthApps } from '../../store/adminAction';
import styles from'./Ouath.module.css'
import { useTypedSelector } from '../../hooks/useTypedSelector';
import Loader from '../Loader/Loader';
import { Button, Modal, Space } from 'antd';
import { OauthAddModal } from '../Modal/OauthAddModal/OauthAddModal';
import Table from 'antd/lib/table';
import { ColumnsType } from 'antd/es/table';
import { IAddOauthAppResponse } from '../../types/types';
import { OauthUpdateModal } from '../Modal/OauthUpdateModal/OauthUpdateModal';
import { adminSlice } from '../../store/adminSlice';

export const Oauth: FC = () => {
  const { isLoading, oauthList, shouldRefetchOauthApps } = useTypedSelector((state) => state.adminReducer);
  const [isOauthAddModalOpen, setIsOauthAddModalOpen] = useState<boolean>(false)
  const [isOauthUpdateModalOpen, setIsOauthUpdateModalOpen] = useState<boolean>(false)
  const dispatch = useAppDispatch();

  const superAdmin = JSON.parse(localStorage.getItem("superAdmin") || "{}");

  useEffect(() => {
    if (shouldRefetchOauthApps) {
      dispatch(getOauthApps(superAdmin))
      dispatch(adminSlice.actions.setShouldRefetchOauthApps(false))
    }
  }, [shouldRefetchOauthApps]);

  const onDeleteApp = (id: string) => {
    const data = {
      superAdmin,
      id,
    }
    dispatch(deleteOauthApp(data))
  }

  const onUpdateApp = (values: IAddOauthAppResponse) => {
    dispatch(adminSlice.actions.updateOauthValue(values))
    setIsOauthUpdateModalOpen(true)
  }

  const openOauthAddModal = () => {
    setIsOauthAddModalOpen(true)
  }

  const columns: ColumnsType<IAddOauthAppResponse> = [
    {
      title: 'Id',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Domain',
      dataIndex: 'domain',
      key: 'domain',
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Requested fields',
      dataIndex: 'requested_fields',
      key: 'requested_fields',
    },
    {
      title: 'Secret',
      dataIndex: 'secret',
      key: 'secret',
    },
    {
      title: 'Action',
      key: 'action',
      render: (_, record) => (
        <Space>
          <button onClick={() => onDeleteApp(record.id)}>Delete</button>
          <button onClick={() => onUpdateApp(record)}>Update</button>
        </Space>
      ),
    },
  ]

  return (
    <div className={styles.oauth}>
      <div className={styles.oauthHeader}>
        <h3 className={styles.title}>Список приложений Oauth</h3>
        <Button
          type="primary"
          onClick={openOauthAddModal}
        >
          Добавить приложение
        </Button>
      </div>
      <div className={styles.oauthBody}>
        {isLoading ? <Loader /> : <Table columns={columns} dataSource={oauthList} />}
      </div>

      <Modal
        visible={isOauthAddModalOpen}
        width={1000}
        title="Добавить приложение"
        footer={null}
        onCancel={() => setIsOauthAddModalOpen(false)}
      >
        <OauthAddModal setIsOauthAddModalOpen={setIsOauthAddModalOpen} />
      </Modal>

      <Modal
        visible={isOauthUpdateModalOpen}
        width={1000}
        title="Обновить приложение"
        footer={null}
        onCancel={() => setIsOauthUpdateModalOpen(false)}
      >
        <OauthUpdateModal setIsOauthUpdateModalOpen={setIsOauthUpdateModalOpen}/>
      </Modal>
    </div>
  );
};