import React, {useEffect} from 'react';
import styles from './Main.module.css';
import {Layout} from 'antd';
import {getAllUsers_Pagination} from "../../store/adminAction";
import {useNavigate} from "react-router-dom";
import Login from "../../components/Login/Login";
import {useAppDispatch} from '../../hooks/useAppDispatch';
import {IDataForAdmin} from "../../types/types";
import {useTypedSelector} from "../../hooks/useTypedSelector";

const Main = () => {
  const superAdmin = localStorage.getItem('superAdmin');
  const dispatch = useAppDispatch()
  const navigate = useNavigate();

  const {count}: IDataForAdmin = useTypedSelector(
    (state) => state.adminReducer
  );

  useEffect(() => {
    if (superAdmin) {
      const data = {
        superAdmin: JSON.parse(superAdmin),
        pagination: {limit: 10, page: 1},
        query: '',
      }
      dispatch(getAllUsers_Pagination(data))
    }
  }, [])

  useEffect(() => {
    if (count) {
      navigate('/admin')
    }
  }, [count])

  return (
    <Layout className={styles.backgroundLayout}>
      <div className='auth'>
        <h1 className='auth__title'>Вход в Админ UniOne</h1>
        <Login/>
      </div>
    </Layout>
  );
};

export default Main;
