import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  IAddOauthAppResponse,
  IAddress,
  IInitState,
  IInn,
  ILogs,
  IOneUser,
  ISuperAdmin,
} from "../types/types";

const initialState: IInitState = {
  users: [],
  oneUser: {
    avatar: "",
    email: "",
    id: "",
    login: "",
    phone: "",
    user_info: {
      birthday: { day: 0, month: 0, year: 0 },
      docs: { id_doc: "", id_doc_name: "", images: [], snils: "", snils_dop: "" },
      geo: { city: "", country: "", fias_addr: "", fias_id: "" },
      passport: { name: "", patronymic: "", sur_name: "" },
      work: { industry: "", inn: "", ogrn: "", place: "", position: "" },
    },
    sex: "",
  },
  users_length: 0,
  isLoading: false,
  errors: "",
  count: 0,
  _token: null,
  result_valid_inn: true,
  errors_inn: "",
  address: [],
  inn: [],
  logs: [],
  codeWords: [],
  _norm: 0,
  oauthList: [],
  shouldRefetchOauthApps: true,
  updatedOauthValue: undefined,
  createdUsers: [],
};

export const adminSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    usersFetchingLoading (state, action: PayloadAction<boolean>) {
      state.isLoading = action.payload;
    },
    usersFetchingSuccess(state, action: PayloadAction<IInitState>) {
      state.isLoading = false;
      state.errors = "";
      state.users = action.payload.users || [];
      state.count = action.payload.count;
    },
    usersFetchingError(state, action: PayloadAction<string>) {
      state.isLoading = false;
      state.errors = action.payload;
    },
    usersFetchForEditSuccess(state, action: PayloadAction<IOneUser>) {
      state.oneUser = action.payload;
    },
    fetchUserToken(state, action: PayloadAction<string | null>) {
      state._token = action.payload;
    },
    fetchError(state, action: PayloadAction<string>) {
      state.errors = action.payload;
    },
    ResultValidateInn(state, action: PayloadAction<boolean>) {
      state.result_valid_inn = action.payload;
    },
    ErrorsInn(state, action: PayloadAction<string>) {
      state.errors_inn = action.payload;
    },
    UserInn(state, action: PayloadAction<IInn[]>) {
      state.inn = action.payload;
    },
    UserAddress(state, action: PayloadAction<IAddress[]>) {
      state.address = action.payload;
    },
    UserPhoto(state, action: PayloadAction<IOneUser | any>) {
      state.oneUser = { ...state.oneUser, avatar: action.payload };
    },
    fetchLogs(state, action: PayloadAction<ILogs[]>) {
      state.logs = action.payload
    },
    fetchCodeWords(state, action: PayloadAction<string[]>) {
      state.codeWords = action.payload;
    },
    updateCodeWord(state, action: PayloadAction<string[]>) {
      state.codeWords = action.payload;
    },
    updateNorm(state, action: PayloadAction<number>) {
      state._norm = action.payload;
    },
    getOauthApps(state, action: PayloadAction<IAddOauthAppResponse[]>) {
      state.oauthList = action.payload
    },
    setShouldRefetchOauthApps(state, action: PayloadAction<boolean>) {
      state.shouldRefetchOauthApps  = action.payload
    },
    updateOauthValue(state, action: PayloadAction<IAddOauthAppResponse>) {
      state.updatedOauthValue = action.payload
    },
    fetchCreatedUsers(state, action: PayloadAction<ISuperAdmin[]>) {
      state.createdUsers = action.payload
    }
  },
});

export default adminSlice.reducer;
