import { DatePicker, Input, Popover, RadioChangeEvent, Space } from 'antd';
import { FC } from 'react';
import { ReactComponent as FilterIcon } from '../../assets/icons/FilterIcon.svg';
import { ParamsProps } from '../../types/types';
import { filterNames, logServices, logTypes } from '../../utils/const';
import { RadioFilter } from './RadioFilter/RadioFilter';

export const Filter: FC<ParamsProps> = ({ title, getLogsFilterCallback }: ParamsProps) => {
  const onSearch = (value: string) => {
    getLogsFilterCallback(value, title)
  };

  const onChange = (radioValue: RadioChangeEvent) => {
    getLogsFilterCallback(radioValue.target.value, title)
  };

  const onRangeChange = (dates: any, dateStrings: string[]) => {
    const dateStart: string = new Date(dateStrings[0]).toISOString();
    const dateEnd: string = new Date(dateStrings[1]).toISOString()

    getLogsFilterCallback(dateStart.concat(' ', dateEnd), title)
  };

  return (
    <Popover
      placement="bottomRight"
      content={(<Space direction="vertical">
        {(title === filterNames.date) ?
          (<DatePicker.RangePicker
            showTime
            format="YYYY/MM/DD HH:mm:ss"
            onChange={onRangeChange}
          />) : (title === filterNames.service) ?
            (<><div id='filter'><Input.Search
              placeholder="Поиск"
              onSearch={onSearch}
              style={{ width: 170 }} /></div>
              <RadioFilter
                values={logServices}
                onChange={onChange} /></>) :
            (<RadioFilter
              values={logTypes}
              onChange={onChange} />)
        }
      </Space>)}
      title={title}
    >

      <FilterIcon style={{ marginLeft: '10px' }} className='active' />
    </Popover>
  );
};
