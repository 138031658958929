import React, {useEffect} from "react";
import {Col, Tabs} from 'antd';
import Admin from "../../components/Admin/Admin";
import Logs from "../../components/Logs/Logs";
import {useNavigate} from "react-router-dom";
import Settings from "../../components/Settings/Settings";
import CodeWords from "../../components/CodeWords/CodeWords";
import { Header } from "../../components/Header/Header"
import { PhoneAuth } from "../../components/PhoneAuth/PhoneAuth";
import { Oauth } from '../../components/Oauth/Oauth';
import {CreateUsers} from "../../components/CreateUsers/CreateUsers";

const {TabPane} = Tabs;

const AdminPanel = () => {
  const navigate = useNavigate()
  const superAdmin = localStorage.getItem('superAdmin');

  useEffect(() => {
    if (!superAdmin) {
      navigate('/')
    }
  }, [])

  return (
    <div>
      <Tabs centered defaultActiveKey={"1"}>
        <TabPane tab="Список пользователей" key="1">
          <Col>
            <Admin/>
          </Col>
        </TabPane>
        <TabPane tab="Логи" key="2">
          <Col>
            <Logs/>
          </Col>
        </TabPane>
        <TabPane tab="Настройки" key="3">
          <Col>
            <Settings/>
          </Col>
        </TabPane>
        <TabPane tab="Кодовые слова" key="4">
          <Col>
            <CodeWords/>
          </Col>
        </TabPane>
        <TabPane tab="Header" key="5">
          <Col>
            <Header/>
          </Col>
        </TabPane>
        <TabPane tab="Работа с СМС" key="6">
          <Col>
            <PhoneAuth/>
          </Col>
        </TabPane>
        <TabPane tab="Oauth" key="7">
          <Col>
            <Oauth/>
          </Col>
        </TabPane>
        <TabPane tab="Создание юзеров" key="8">
          <Col>
            <CreateUsers/>
          </Col>
        </TabPane>
      </Tabs>
    </div>
  );
};

export default AdminPanel;
