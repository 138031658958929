import React from 'react';
import {Input} from "antd";
import { IInputProfile } from '../../../../types/types';


const Index = ({name, value, onChange, type, placeholder, disabled, onFocus}: IInputProfile) => {
  return <Input
    name={name}
    value={value}
    onChange={onChange}
    type={type}
    placeholder={placeholder}
    allowClear
    disabled={disabled}
    onFocus={onFocus}
  />
};

export default Index;