export const emailValid = new RegExp(/^((([0-9A-Za-z]{1}[-0-9A-z\.]{1,}[0-9A-Za-z]{1})|([0-9А-Яа-я]{1}[-0-9А-я\.]{1,}[0-9А-Яа-я]{1}))@([-A-Za-z]{1,}\.){1,2}[-A-Za-z]{2,})$/u)
export const phoneValid = new RegExp(/^((8|\+7)[\- ]?)?(\(?\d{3}\)?[\- ]?)?[\d\- ]{7,10}$/)
export const calendarMask = [/\d/, /\d/, ".", /\d/, /\d/, ".", /\d/, /\d/, /\d/, /\d/]
export const fioValid = new RegExp(/^(?=.{1,40}$)[а-яёА-ЯЁ]+(?:[-' ][а-яёА-ЯЁ]+)*$/)

const errosServ = [
    {key: 99, message: "Неизвестная ошибка"},
    {key: 100, message: "Пустой запрос"},
    {key: 101, message: "Запрос на чтение ошибки"},
    {key: 102, message: "Ошибка запроса демаршалировать"},
    {key: 103, message: "Неверные данные"},
    {key: 104, message: "Неправильный метод"},
    {key: 105, message: "Токен не найден"},
    {key: 106, message: "Пользователь не найден"},
    {key: 107, message: "Путь не разрешен"},
    {key: 108, message: "Ошибка отправки данных регистрации пользователя"},
    {key: 109, message: "Введен некорректный номер телефона"},
    {key: 110, message: "Неправильно ввели адрес электронной почты пользователя"},
    {key: 111, message: "Неверный пароль пользователя"},
    {key: 112, message: "Телефон уже существует"},
    {key: 113, message: "Email уже существует"},
    {key: 114, message: "Ошибка добавить пользователя"},
    {key: 115, message: "Ошибка отправки данных авторизации пользователя"},
    {key: 116, message: "Электронная почта и телефон не переданы"},
    {key: 117, message: "Пароль не прошел"},
    {key: 118, message: "Электронная почта или телефон или пароль не верны"},
    {key: 119, message: "Ошибка отправки смс"},
    {key: 120, message: "Код не прошел"},
    {key: 121, message: "Код неверный"},
    {key: 122, message: "Ошибка проверки кода пользователя"},
    {key: 123, message: "Токен не активный"},
    {key: 124, message: "Ошибка обновления пользователя"},
    {key: 127, message: "Текущий пользователь не является администратором"},
    {key: 137, message: "Неправильный сервис для заданной роли"},
    {key: 158, message: "Такой номер существует"},
    {key: 164, message: "Ошибка данных снилс"},
    {key: 166, message: "Адрес электронной почты пользователя уже установлен"},
    {key: 170, message: "Такой СНИЛС уже зарегистрирован"},
    {key: 171, message: "Вы не можете запросить код более одного раза в две минуты"},
    {key: 172, message: "Пользователь удален или заблокирован"},
    {key: 190, message: "Ошибка получения логов из обертки"},
    {key: 193, message: "Неверные параметры пагинации"}
]

export const errorsKey = (key: number) => errosServ.filter(er => er.key === key).map(item => item.message)


