import { adminSlice } from "../store/adminSlice";

// Валидация ИНН
export function validateInn(inn: string | number) {
  var result = false;
  return async (dispatch: any) => {
    if (typeof inn === 'number') {
      inn = inn.toString();
    } else if (typeof inn !== 'string') {
      inn = '';
    }
    if (!inn.length) {
      dispatch(adminSlice.actions.ErrorsInn('ИНН пуст'));
    } else if (/[^0-9]/.test(inn)) {
      dispatch(adminSlice.actions.ErrorsInn('ИНН может состоять только из цифр'));
    } else if ([10, 12].indexOf(inn.length) === -1) {
      dispatch(adminSlice.actions.ErrorsInn('ИНН может состоять только из 10 или 12 цифр'));
    } else {
      var checkDigit = function (inn: any, coefficients: any) {
        var n = 0;
        for (var i in coefficients) {
          n += coefficients[i] * inn[i];
        }
        return Math.floor(n % 11 % 10);
      };
      switch (inn.length) {
        case 10:
          var n10 = checkDigit(inn, [2, 4, 10, 3, 5, 9, 4, 6, 8]);
          if (n10 === parseInt(inn[9])) {
            result = true;
          }
          break;
        case 12:
          var n11 = checkDigit(inn, [7, 2, 4, 10, 3, 5, 9, 4, 6, 8]);
          var n12 = checkDigit(inn, [3, 7, 2, 4, 10, 3, 5, 9, 4, 6, 8]);
          if ((n11 === parseInt(inn[10])) && (n12 === parseInt(inn[11]))) {
            result = true;
          }
          break;
      }
      if (!result) {
        dispatch(adminSlice.actions.ErrorsInn('Неправильное контрольное число'));
      }
    }
    dispatch(adminSlice.actions.ResultValidateInn(result))
  }
}