import React, {FC} from 'react';
import {Row, Spin} from "antd";

const Loader: FC = () => {
   return (
      <Row style={{height: '60vh', position: "fixed", width: "100%"}} justify={"center"} align={"middle"}>
         <Spin size={"large"}/>
         <h1 style={{color: '#5b97e1', marginLeft: 30}}>Идет загрузка страницы...</h1>
      </Row>
   );
};

export default Loader;