import React, {useEffect, useState} from 'react';
import {Button, Col, Form, Input, Row} from "antd";
import styles from './Login.module.css';
import {getAllUsers_Pagination} from '../../store/adminAction';
import {useNavigate} from 'react-router-dom';
import { useAppDispatch } from '../../hooks/useAppDispatch';
import {IDataForAdmin} from "../../types/types";
import {useTypedSelector} from "../../hooks/useTypedSelector";

const Anytime = () => {
  const [login, setLogin] = useState<string>('')
  const [password, setPassword] = useState<string>('')
  const { count }: IDataForAdmin = useTypedSelector(
     (state) => state.adminReducer
  );

  const [form] = Form.useForm();
  const dispatch = useAppDispatch()
  const navigate = useNavigate();

  const handlerClick = async () => {
    const data = {
      superAdmin: {login, password},
      pagination: {limit: 10, page: 1},
      query: '',
    }
    dispatch(getAllUsers_Pagination(data))
  }

  useEffect(() => {
    if (count) {
      navigate('/admin')
    }
  }, [count])

  return (
    <Row justify={"center"} align={"middle"}>
      <Col className={styles.colWidth}>
        <Form form={form} layout="vertical">
          <Form.Item
            name="login"
            rules={[{required: true, message: 'Пожалуйста, введите ваш логин!'}]}
          >
            <Input
              allowClear
              onChange={e => setLogin(e.target.value)}
              placeholder='логин'
            />
          </Form.Item>

          <Form.Item
            name="password"
            rules={[{required: true, message: 'Пожалуйста, введите ваш пароль!'}]}
          >
            <Input.Password
              allowClear
              value={password}
              onChange={e => setPassword(e.target.value)}
              placeholder="пароль"
            />
          </Form.Item>

          <Form.Item shouldUpdate>
            {() => (
              <Button
                type="primary"
                block
                className={styles.buttonLogin}
                disabled={!form.isFieldsTouched(true) || !!form.getFieldsError().filter(({errors}) => errors.length).length}
                onClick={handlerClick}
              >
                Войти
              </Button>
            )}
          </Form.Item>
        </Form>
      </Col>
    </Row>
  );
};

export default Anytime;