import {useEffect, useState} from "react";
import {Avatar, Col, Row} from "antd";
import Text from "antd/es/typography/Text";
import styles from './Avatar.module.css'
import IsModalImage from "../../../../style/IsModalImage";
import Icon from "@ant-design/icons";
import {AvatarIconForEdit} from "../../../../assets/icons/AvatarIconForEdit";
import {BASE_BUCKET} from "../../../../utils/const";
import {base_image_url} from "../../../../services";
import { IAvatar } from "../../../../types/types";


const Index = (props: IAvatar) => {
    const avatar = props.avatar;
    const _id = props._id;
    const [modal, setModal] = useState<boolean>(false)
    const [avatarUrl, setAvatarUrl] = useState<string>('');

    useEffect(() => {
        if (avatar) {
            setAvatarUrl(`${base_image_url}/${BASE_BUCKET}/${avatar}`);
        }
    },[avatar])

    return (
        <Row justify='center'>
            <Col className={styles._marginTop100}>
                <Col className={styles._avatarCenter}>
                    <span onClick={() => setModal(!modal)}>
                    <Avatar
                        className={styles.ava}
                        size={{xs: 130, sm: 140, md: 150, lg: 180, xl: 180, xxl: 180}}
                        icon={<Icon component={AvatarIconForEdit}/>}
                        src={avatarUrl}
                        alt="avatar"/>
                    </span>
                    <IsModalImage modal={modal} setModal={setModal}/>
                </Col>
                <Col>
                    <Text className={styles.avatarText} type='secondary'>ID:{_id}</Text>
                </Col>
            </Col>
        </Row>
    );
};

export default Index;
