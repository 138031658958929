import React, {FC, useCallback, useState} from 'react';
import {Button, message, Modal} from "antd";
import Text from "antd/es/typography/Text";
import {getCroppedImg} from "../../utils/canvasUtils";
import styles from "./IsModalImage.module.css";
import Cropper from "react-easy-crop";
import {PaperClipIcon} from "../../assets/icons/PaperClipIcon";
import Icon from "@ant-design/icons";
import { upload_file } from '../../store/adminAction';
import { IIsModalImage } from '../../types/types';
import { useTypedSelector } from '../../hooks/useTypedSelector';
import { useAppDispatch } from '../../hooks/useAppDispatch';



const IsModalImage: FC<IIsModalImage> = ({modal, setModal}) => {
  const [highlight, setHighlight] = useState<boolean>(false);
  const [drop, setDrop] = useState<boolean>(false);

  const [imageSrc, setImageSrc] = useState<string>('')
  const [crop, setCrop] = useState<any>({x: 0, y: 0})
  const [zoom, setZoom] = useState<number>(1)
  const [croppedAreaPixels, setCroppedAreaPixels] = useState<any>(null)
  const [loading, setLoading] = useState<boolean>(false)
  const { _token } = useTypedSelector(state => state.adminReducer);

  const dispatch = useAppDispatch()

  const onCropComplete = useCallback((croppedArea: any, croppedAreaPixels: any) => {
    setCroppedAreaPixels(croppedAreaPixels)
  }, [])

  const showCroppedImage = useCallback(async () => {
    setLoading(true)
    try {
      const croppedImageFile = await getCroppedImg(imageSrc, croppedAreaPixels)
      dispatch(upload_file({token: _token, file: croppedImageFile}))

      setTimeout(() => {
        setModal(false)
        setLoading(false)
      }, 1000)

    } catch (e) {
      console.error(e)
    }

  }, [imageSrc, croppedAreaPixels])

  const handleEnter = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    imageSrc === "" && setHighlight(true);
  };

  const handleOver = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    imageSrc === "" && setHighlight(true);
  };

  const handleLeave = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    setHighlight(false);
  };

  const handleUpload = (e: any) => {
    const size = ((e.target.files[0].size / 1024) / 1024).toFixed(2);
    if (+size < 5) {
      e.preventDefault();
      e.stopPropagation();
      setHighlight(false);
      setDrop(true);

      const [file] = e.target.files || e.dataTransfer.files;

      uploadFile(file);
    } else {
      message.warning("Загружайте меньше 5 МБ");
    }
  };

  function uploadFile(file: any) {
    const reader: any = new FileReader();
    reader.readAsBinaryString(file);

    reader.onload = () => {
      const fileRes = btoa(reader.result);
      setImageSrc(`data:image/jpg;base64,${fileRes}`);
    };

    reader.onerror = () => {
      console.log("There is a problem while uploading...");
    };
  }


  return (
    <Modal
      width={1468}
      centered={true}
      bodyStyle={{height: 528}}
      className={styles._avatarModal}
      title={'Изменить аватар'}
      visible={modal}
      onCancel={() => setModal(!modal)}
      footer={[
        <div className={styles._footerButton_Body}>
          <div className={styles._buttonAnother}>
            <label>
              <Text className={styles.labelButton}> Выбрать другой файл</Text>
              <input className={styles.inputStyle} type="file"
                     accept=".png,.jpeg,.jpg"
                     onChange={(e) => handleUpload(e)}/>
            </label>

          </div>

          <div>
            <Button loading={loading} type="primary" onClick={showCroppedImage}>
              Сохранить
            </Button>
          </div>
        </div>
      ]}>
      <label className={styles.avatarLabel}>
        <div
          className={styles.avatarDasher}
          onDragEnter={(e) => handleEnter(e)}
          onDragLeave={(e) => handleLeave(e)}
          onDragOver={(e) => handleOver(e)}
          onDrop={(e) => handleUpload(e)}
        >
          {imageSrc
            ? <div className={styles._avatarHaveImage}>
              <Cropper
                style={{
                  containerStyle: {
                    marginTop: 105,
                    width: '100%',
                    height: '68.7%'
                  }
                }}
                image={imageSrc}
                crop={crop}
                aspect={1}
                cropShape="round"
                zoom={zoom}
                onCropChange={setCrop}
                onCropComplete={onCropComplete}
                onZoomChange={setZoom}
              />
            </div>
            : <div className={styles._borderNotImage}>
              <input className={styles.inputStyle} type="file" accept=".png,.jpeg,.jpg"
                     onChange={(e) => handleUpload(e)}
              />
              <div className={styles._textBorder}>
                <Icon className={styles._paperClip} component={PaperClipIcon}/>
                <Text>
                  Переместите файлы сюда или <span>выберите файлы</span>
                </Text>
              </div>
            </div>
          }
        </div>
      </label>
    </Modal>
  );
};

export default IsModalImage;