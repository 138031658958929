import { Button, Col, Input, Row, Select, SelectProps } from "antd";
import { ChangeEvent, useEffect, useState } from "react";
import { useAppDispatch } from "../../../hooks/useAppDispatch";
import { addHeader, updateHeader } from "../../../store/adminAction";
import { headerMethod, IElements, IHeaderProps } from "../../../types/types";
import { logServices } from "../../../utils/const";
import styles from './UpdateModal.module.css'

export const UpdateModal: React.FC<IHeaderProps> = ({ item, method, close }: IHeaderProps) => {
  const [data, setData] = useState<IElements>(item);
  const dispatch = useAppDispatch();

  const options: SelectProps['options'] = logServices.map(val => {
    return {
      value: val,
      label: val
    }
  })

  const superAdmin = JSON.parse(localStorage.getItem("superAdmin") || "{}");

  useEffect(() => {
    setData(item)
  }, [item])

  const updateField = (
    key: string,
    event: ChangeEvent<HTMLInputElement>,
  ): void => {
    setData(prev => ({
      ...prev,
      [key]: event.target.value,
    }))
  };

  const updateOption = (value: string | string[]) => {
    if (typeof value === 'string') {
      setData(prev => ({
        ...prev,
        'sets': [value],
      }))
    } else {
      setData(prev => ({
        ...prev,
        'sets': [...value],
      }))
    }
  };

  const update = () => {
    data.order = Number(data.order)
    dispatch(updateHeader({ ...superAdmin, ...data }))
      .then(() => {
        close();
      })
  }

  const add = () => {
    data.order = Number(data.order)
    setData(prev => ({
      ...prev,
      'sets': [String(data.sets)],
    }))
    dispatch(addHeader({ ...superAdmin, ...data }))
      .then(() => {
        close();
      })
  }

  return (
    <>
      <Input.Group size="large">
        {
          Object.entries(data).map((pair) => {
            return (
              <Row className={styles.update_modal_row} gutter={8}>
                <Col span={3}>
                  <h3>{pair[0]}</h3>
                </Col>
                <Col span={8}>
                  {
                    pair[0] === 'sets' ?
                      (
                        <Select
                          mode="multiple"
                          placeholder="Please select"
                          onChange={(e) => updateOption(e)}
                          className={styles['update_modal_input']}
                          value={pair[1] ? pair[1] : undefined}
                          options={options}
                        />
                      ) : (
                        <Input
                          className={styles['update_modal_input']}
                          value={pair[1]}
                          onChange={(e) => updateField(pair[0], e)}
                        />
                      )
                  }
                </Col>
              </Row>
            )
          })
        }
      </Input.Group>
      <Button
        className={styles.update_modal_cancel_button}
        type="dashed"
        size={'middle'}
        onClick={() => close()}>
        Cancel
      </Button>
      {
        method === headerMethod.ADD ? (
          <Button
            className={styles.update_modal_add_button}
            type="primary"
            size={'middle'}
            onClick={() => { add() }}>
            Add
          </Button>
        ) : (
          <Button
            className={styles.update_modal_add_button}
            type="primary"
            size={'middle'}
            onClick={() => { update() }}>
            Update
          </Button>
        )
      }
    </>
  )
}
