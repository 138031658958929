import React, {ChangeEvent, FC, useEffect, useState} from 'react';
import styles from "./CodeWords.module.css";
import {Button, Input, Modal} from "antd";
import {DeleteIcon} from "../../assets/icons/DeleteIcon";
import Icon from "@ant-design/icons";
import {useAppDispatch} from "../../hooks/useAppDispatch";
import {useTypedSelector} from "../../hooks/useTypedSelector";
import {get_list_words, set_list_words} from "../../store/adminAction";
import TextArea from "antd/es/input/TextArea";

const CodeWords: FC = () => {
  const {codeWords} = useTypedSelector((state) => state.adminReducer)
  const userData = localStorage.getItem('superAdmin')
  const [dataCodeWords, setDataCodeWords] = useState<string[]>([])
  const [packWords, setPackWords] = useState<string>('')
  const [packModal, setPackModal] = useState<boolean>(false)
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (userData) {
      dispatch(get_list_words(userData))
    }
  }, [])

  useEffect(() => {
    setDataCodeWords(codeWords)
  }, [codeWords])

  const addWords = () => {
    setDataCodeWords(['', ...dataCodeWords])
  }

  const deleteWord = (e: React.MouseEvent, index: number,): void => {
    e.preventDefault()
    const newCodeWords = dataCodeWords.filter((item: string, i: number) => i !== index)
    setDataCodeWords(newCodeWords)
  }

  const updateWord = (e: ChangeEvent<HTMLInputElement>, word: string, index: number): void => {
    const newData = dataCodeWords.map((el, i) => {
      if (index === i) {
        el = e.target.value;
      }
      return el
    })
    setDataCodeWords(newData)
  }

  const saveWords = (): void => {
    const lowerCaseList = dataCodeWords.map(item => {
      return item.toLowerCase()
    })
    if (userData) {
      const values = JSON.parse(userData)
      dispatch(set_list_words({userData: values, list: lowerCaseList}))
    }
  }

  const savePackWords = () => {
    if (packWords.length) {
      const newData = packWords.split(' ')
      setPackModal(false)
      setDataCodeWords([...newData])
      setPackWords('')
    }
  }

  const openPackModal = () => {
    setPackWords(dataCodeWords.join(' '))
    setPackModal(true)
  }

  return (
    <div className={styles.body}>
      <h1 className={styles.title}>Список кодовых слов</h1>

      <button
        className={styles.addWord}
        onClick={addWords}
      >
        Добавить новое слово
      </button>

      <button
        className={styles.addWord}
        onClick={openPackModal}
      >
        Добавить новые слова
      </button>

      <button
        className={styles.saveWords}
        onClick={saveWords}
      >
        Сохранить кодовые слова
      </button>

      <form className={styles.form}>
        {dataCodeWords.map((item, index) => (
          <div className={styles.elem} key={index}>
            <Input
              name='value'
              className={styles.input}
              value={item}
              maxLength={5}
              placeholder={'Введите ваше слово'}
              onChange={(e) => updateWord(e, item, index)}
            />
            <button
              className={styles.delete}
              onClick={(e) => deleteWord(e,index)}
            >
              <Icon
                component={DeleteIcon}
                className={styles.deleteBtn}
              />
            </button>
          </div>
        ))}
      </form>

      <Modal
        visible={packModal}
        footer={[
          <div className={styles.modalButtons}>
            <Button type='primary' onClick={() => setPackModal(false)} danger> Отмена </Button>
            <Button type='primary' onClick={savePackWords}> Сохранить </Button>
          </div>
        ]}
        title={
          <>
            <h1>Добавить новые слова</h1>
            <p>Вы можете добавить несколько слов через пробел</p>
          </>
        }
        onCancel={() => setPackModal(false)}
      >

        <TextArea
          rows={5}
          value={packWords}
          placeholder={'Вы можете добавлять слова через пробел'}
          onChange={(e) => setPackWords(e.target.value)}
          required
        />
      </Modal>
    </div>
  );
};

export default CodeWords;