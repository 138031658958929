import React, { useEffect, useMemo, useState } from "react";
import styles from "./Admin.module.css";
import { Dropdown, Empty, Input, Menu, Modal, Select, Tag } from "antd";
import Icon, { EditOutlined } from "@ant-design/icons";
import { DeleteIcon } from "../../assets/icons/DeleteIcon";
import { SearchIcon } from "../../assets/icons/SearchIcon";
import {
  activate_user,
  deactivate_user,
  delete_user,
  deletePersonalUser,
  get_user_token,
  getAllUsers_Pagination, restore_user,
} from "../../store/adminAction";
import { CooliconIcon } from "../../assets/icons/CooliconIcon";
import { AvatarIcon } from "../../assets/icons/AvatarIcon";
import UserEditModule from "../Modal/UserEditModal/UserEditModule";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { IsAdminIcon } from "../../assets/icons/IsAdminIcon";
import { IDataForAdmin } from "../../types/types";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import Loader from "../Loader/Loader";
import { postPhoneFunc, toCapitalize } from "../../utils/phoneInputFunctions";

const Admin = () => {
  const [pagination, setPagination] = useState<number>(10);
  const [counter, setCounter] = useState<number>(1);
  const [countPagination, setCountPagination] = useState<number>(1);
  const [changePrevAndNext, setChangePrevAndNext] = useState<boolean>(false);
  const [prevCount, setPrevCount] = useState<number>(countPagination);
  const [fieldsInput, setFieldsInput] = useState<string>("");
  const [search, setSearch] = useState<boolean>(false);
  const [isEditModule, setIsEditModule] = useState<boolean>(false);
  const [idForRole, setIdForRole] = useState<string | null>('');
  const Option = Select.Option;
  const { users, count, oneUser, isLoading }: IDataForAdmin = useTypedSelector(
    (state) => state.adminReducer
  );

  const dispatch = useAppDispatch();
  const BASE_BUCKET = "basebucket";

  const superAdmin = JSON.parse(localStorage.getItem("superAdmin") || "{}");

  useEffect(() => {
    const data = {
      superAdmin,
      pagination: { limit: Number(pagination), page: 1 },
      query: fieldsInput,
    };
    dispatch(getAllUsers_Pagination(data));
  }, []);

  const changePageSizeForPagination = (e: string) => {
    const data = {
      superAdmin,
      pagination: { limit: Number(e), page: 1 },
      query: fieldsInput,
    };
    dispatch(getAllUsers_Pagination(data));
    setPagination(Number(e));
    setCounter(1);
    setCountPagination(1);
    setSearch(false);
  };

  const arrowRight = () => {
    if (counter < Math.ceil(count / pagination)) {
      setCounter(counter + 1);
      setChangePrevAndNext(true);
      setCountPagination(countPagination + Number(pagination));
      const data = {
        superAdmin,
        pagination: { limit: Number(pagination), page: counter + 1 },
        query: fieldsInput,
      };
      dispatch(getAllUsers_Pagination(data));
    }
  };

  const arrowLeft = () => {
    if (counter > 1) {
      setCounter(counter - 1);
      setChangePrevAndNext(false);
      setPrevCount(countPagination);
      setCountPagination(countPagination - Number(pagination));
      const data = {
        superAdmin,
        pagination: { limit: Number(pagination), page: counter - 1 },
        query: fieldsInput,
      };
      dispatch(getAllUsers_Pagination(data));
    }
  };

  const searchUsers = (e: React.ChangeEvent<HTMLInputElement>) => {
    const data = {
      superAdmin,
      pagination: { limit: Number(pagination), page: 1 },
      query: postPhoneFunc(toCapitalize(e.target.value)),
    };
    dispatch(getAllUsers_Pagination(data));
    setFieldsInput(postPhoneFunc(toCapitalize(e.target.value)));
    setSearch(true);
    setCounter(1);
    setCountPagination(1);
  };

  useEffect(() => {
    if (oneUser.id) {
      setIsEditModule(true);
    }
  }, [oneUser]);

  const getUserToken = (id: string , service: string | undefined) => {
    setIdForRole(id);
    dispatch(get_user_token({superAdmin, user_id: id, service}));
  };

  const convertToThumbFormat = (url: string | number) => {
    const strUrl = url.toString()
    const splitted = strUrl.split(".");
    const miniatureImg = `${splitted[0]}_thumb.${splitted[1]}`;
    return `${process.env.REACT_APP_BASE_IMAGE_URL}/${BASE_BUCKET}/${miniatureImg}`;
  };

  const memoizedValue = useMemo(() => {
    let value: any
    if (changePrevAndNext) {
      if (countPagination + (pagination - 1) > count) {
        value = count;
      } else {
        value = countPagination + (pagination - 1);
      }
    } else if (!changePrevAndNext) {
      if (countPagination - (pagination - 1) < 0) {
        if (pagination >= count) {
          value = count || pagination;
        } else {
          value = pagination;
        }
      } else {
        value = prevCount - 1;
      }
    }

    if (users && search) {
      if (count < value || fieldsInput) {
        if (countPagination + Number(pagination) - 1 < count) {
          return countPagination + Number(pagination) - 1;
        } else {
          return count;
        }
      } else {
        if (value < pagination) {
          return pagination;
        } else {
          return value;
        }
      }
    } else if (!users) {
      return 0;
    } else {
      return value;
    }
  }, [changePrevAndNext, pagination, countPagination, fieldsInput, users]);

  const statusLocalisation = (status: string) => {
    if (status === "deleted") {
      return "Удален";
    } else if (status === "blocked") {
      return "Деактивирован";
    } else if (status === "confirmed") {
      return "Подтвержден";
    } else {
      return "Активен";
    }
  };

  const phoneMask = (phone: any) => {
    const mask = /(\+7|8)[\s(]?(\d{3})[\s)]?(\d{3})[\s-]?(\d{2})[\s-]?(\d{2})/g;
    return phone.replace(mask, "+7 ($2) $3-$4-$5");
  };

  const dateMask = (date: any) => {
    if (date[0] === "2") {
      const mask = /[\-]/g;
      return date.substr(0, 10).replace(mask, ".");
    } else {
      return null;
    }
  };

  const onCancel = () => {
    setIsEditModule(false);
  };

  const statusClassName = (status: string) => {
    if (status === "active") {
      return "activeStatus";
    } else if (status === "deleted") {
      return "deletedStatus";
    } else if (status === "blocked") {
      return "blockedStatus";
    } else if (status === "confirmed") {
      return "confirmedStatus";
    }
  };

  const onDeletePersonalUser = (id: string) => {
    dispatch(
      deletePersonalUser({
        superAdmin,
        user_id:id,
        pagination: {limit: Number(pagination), page: counter},
        query: fieldsInput
      })
    )
  }

  const menu = (id: string, status: string) => {
    return (
      <Menu
        items={[
          {
            key: 1,
            label:
              status === "deleted" ? (
                <button
                  className={styles.actionBtn}
                  onClick={() =>
                    dispatch(
                      restore_user({
                        superAdmin,
                        user_id: id,
                        pagination: {
                          limit: Number(pagination),
                          page: counter,
                        },
                        query: fieldsInput,
                      })
                    )
                  }
                >
                  Восстановить пользователя
                </button>
              ) : (
                <button
                  className={styles.actionBtn}
                  onClick={() =>
                    dispatch(
                      delete_user({
                        superAdmin,
                        user_id: id,
                        pagination: {
                          limit: Number(pagination),
                          page: counter,
                        },
                        query: fieldsInput,
                      })
                    )
                  }
                >
                  Удалить пользователя
                </button>
              ),
          },
          {
            key: 2,
            label:
              status === "blocked" ? (
                <button
                  className={styles.actionBtn}
                  onClick={() =>
                     dispatch(
                        activate_user({
                          superAdmin,
                          user_id: id,
                          pagination: {limit: Number(pagination), page: counter},
                          query: fieldsInput
                        })
                     )
                  }
                >
                  Активировать пользователя
                </button>
              ) : (
                <button
                  className={styles.actionBtn}
                  onClick={() =>
                    dispatch(
                      deactivate_user({
                        superAdmin,
                        user_id: id,
                        pagination: {limit: Number(pagination), page: counter},
                        query: fieldsInput
                      })
                    )
                  }
                >
                  Деактивировать пользователя
                </button>
              ),
          },
          {
            key: 3,
            label: (
              <button
                className={styles.actionBtn}
                onClick={() => onDeletePersonalUser(id)}
              >
                Удалить персонального пользователя
              </button>
            ),
          },
        ]}
      />
    );
  };


  return (
      <div className={styles._body}>
        <div className={styles._bodyHeader}>
          <div className={styles._bodyHeaderChildBlock}>
            <div className={styles._listUser}>Список пользователей</div>
            <div className={styles._forChange}>
              Для редактирования данных пользователя кликните
              <br/>
              по соответствующей строке таблицы
            </div>
          </div>
        </div>

        <Input
            className={styles._input}
            placeholder={" Начните вводить текст для поиска"}
            prefix={<Icon component={SearchIcon} className={styles.prefix}/>}
            onPressEnter={searchUsers}
        />

        <Modal
            visible={isEditModule}
            onCancel={onCancel}
            className={styles.EditModalProfile}
            title={false}
            footer={false}
            width={1000}
        >
          <UserEditModule
              modal={isEditModule}
              id={idForRole}
              superAdmin={superAdmin}
              setIsEditModule={setIsEditModule}
          />
        </Modal>


        <div className={styles._tableBlock}>
          <table className={styles._table}>
            <thead>
            <tr className={styles.tableThead}>
              <th>ID</th>
              <th>Аватар</th>
              <th>ФИО</th>
              <th>Роль</th>
              <th>Телефон</th>
              <th>E-mail</th>
              <th>Статус</th>
              <th>Создан</th>
              <th>Удален</th>
              <th/>
            </tr>
            </thead>
            {
              isLoading ? <Loader /> : (
                <tbody>
                {users.length ? (
                  users.map((item) => (
                    <tr key={item.id} className={styles.tableTbody}>
                      <td>{item.id}</td>

                      <td className={styles.avatarBlock}>
                        {item.avatar ? (
                          <img
                            src={convertToThumbFormat(item.avatar)}
                            alt="user_avatar"
                          />
                        ) : (
                          <Icon
                            component={AvatarIcon}
                            className={styles.avatarIcon}
                          />
                        )}
                      </td>

                      <td className={styles.passportBlock}>
                        <span>
                          {item.user_info.passport.sur_name + " "}
                          {item.user_info.passport.name + " "}
                          {item.user_info.passport.patronymic}
                        </span>
                        <Icon
                          component={
                            item.roles.find(item => item.role === 'admin')
                              ? IsAdminIcon
                              : undefined
                          }
                          className={styles.ellipseDrots}
                        />
                      </td>

                      <td>
                        {item.roles?.map((user_class, index) => (
                          <div key={user_class.role + index}>
                            <div>{user_class.role}</div>
                            <Tag
                              className={
                                user_class.service ? styles.tags : styles.tagsNone
                              }
                              key={`id_key_${index}`}
                            >
                              {user_class.service}
                            </Tag>
                          </div>
                        ))}
                      </td>

                      <td>
                        <div className={styles.phoneBlock}>
                          <Icon
                            component={CooliconIcon}
                            className={styles.phoneBlockIcon}
                          />
                          <span>{phoneMask(item.phone)}</span>
                        </div>
                      </td>

                      <td>{item.email}</td>

                      <td className={styles[`${statusClassName(item.status)}`]}>
                        {statusLocalisation(item.status)}
                      </td>

                      <td>{dateMask(item.created_at)}</td>

                      <td>{dateMask(item.deleted_at)}</td>

                      <td className={styles.columnRight}>
                        <EditOutlined
                          onClick={() =>
                            getUserToken(String(item.id), item.roles[0]?.service)
                          }
                          className={styles.editBtn}
                        />

                        <Dropdown
                          overlay={menu(String(item.id), item.status)}
                          placement="bottomRight"
                        >
                          <Icon
                            component={DeleteIcon}
                            className={styles.deleteBtn}
                          />
                        </Dropdown>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={10}>
                      <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                    </td>
                  </tr>
                )}
                </tbody>
              )
            }
          </table>
        </div>

        {
          isLoading ? "" : (
              <div className={styles.tableFooter}>
                <div className={styles.record}>Всего записей: {count}</div>
                <div className={styles.tableFooterChildBlock}>
                  <div className={styles.select}>
                    На странице по :
                    <Select defaultValue={"10"} onChange={changePageSizeForPagination}>
                      <Option value="5">5</Option>
                      <Option value="10">10</Option>
                      <Option value="20">20</Option>
                      <Option value="50">50</Option>
                      <Option value="100">100</Option>
                    </Select>
                  </div>
                  <div className={styles.paginationCounter}>
                    {countPagination}-{memoizedValue}
                    <span> из </span> {count}
                  </div>
                  <div className={styles.arrow}>
                    <p>
                      <i className={styles.arrow_left} onClick={arrowLeft}/>
                    </p>
                    <p>
                      <i className={styles.arrow_right} onClick={arrowRight}/>
                    </p>
                  </div>
                </div>
              </div>
          )
        }
      </div>
  );
};

export default Admin;
