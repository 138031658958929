import React, {FC} from "react";
import styles from "./Settings.module.css";
import {Button} from "antd";
import {update_pull} from "../../store/adminAction";
import {useAppDispatch} from "../../hooks/useAppDispatch";

const Settings: FC = () => {
   const dispatch = useAppDispatch();
   const key = "PdSgVkYp-JaNdRgUC&F)J@Nc9y$B&E)Hs6v9y/B?Xp2s5v8yfU"
   const updatePull = () => {
      dispatch(update_pull(key))
   }
   return (
      <div className={styles._body}>
         <div className={styles.button}>
            <Button
               type="primary"
               block
               onClick={updatePull}
            >
               Обновить пулл
            </Button>
         </div>
      </div>
   );
};

export default Settings;
