import {Route, Routes} from "react-router-dom";
import Main from "./pages/Main/Main";
import Admin from "./pages/AdminPanel/AdminPanel";

function App() {
  return (
    <Routes>
      <Route path={'/'} element={<Main/>}/>
      <Route path={'admin'} element={<Admin/>}/>
    </Routes>
  );
}

export default App;
