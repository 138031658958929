import React, { FC, useLayoutEffect } from 'react';
import { Button, Col, Form, Row, Select } from 'antd';
import InputPforile from '../UserEditModal/InputProfile';
import { useAppDispatch } from '../../../hooks/useAppDispatch';
import { IOnFinishValues } from '../../../types/types';
import { useTypedSelector } from '../../../hooks/useTypedSelector';
import { updateOauthApp } from '../../../store/adminAction';
import { options } from '../../../utils/const';

interface IOauthUpdateModal {
  setIsOauthUpdateModalOpen: (b: boolean) => void
}

export const OauthUpdateModal: FC<IOauthUpdateModal> = ({ setIsOauthUpdateModalOpen }) => {
  const { updatedOauthValue } = useTypedSelector((state) => state.adminReducer);
  const dispatch = useAppDispatch();
  const [form] = Form.useForm();
  const superAdmin = localStorage.getItem('superAdmin');

  const setAppData = () => {
    if (updatedOauthValue) {
      try {
        form.setFieldsValue({
          domain: updatedOauthValue.domain,
          name: updatedOauthValue.name,
          requested_fields: updatedOauthValue.requested_fields,
        })

      } catch (e) {
        console.error(e)
      }
    }
  }

  const onReset = () => {
    form.resetFields();
  };

  const onFinish = (values: IOnFinishValues): void => {
    if (superAdmin && updatedOauthValue) {
      const data = {
        superAdmin: JSON.parse(superAdmin),
        values: {
          ...values,
          id: updatedOauthValue.id
        }
      }
      dispatch(updateOauthApp(data))
      setIsOauthUpdateModalOpen(false)
    }
  };

  useLayoutEffect(() => {
    setAppData()
  }, [])

  return (
    <Form
      form={form}
      layout='vertical'
      onFinish={onFinish}
    >
      <Row gutter={[20, 20]}>
        <Col span={12}>
          <Form.Item
            hasFeedback
            rules={[{required: true, message: "Введите домен"}]}
            label="Домен"
            name="domain"
          >
            <InputPforile type="text" placeholder="Введите домен" />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            hasFeedback
            rules={[{required: true, message: "Введите имя"},]}
            label="Имя"
            name="name"
          >
            <InputPforile type="text" placeholder="Введите имя" />
          </Form.Item>
        </Col>
      </Row>

      <Row>
        <Col span={24}>
          <Form.Item
            label="Выберите поля"
            name="requested_fields"
          >
            <Select
              mode="multiple"
              allowClear
              style={{width: '100%'}}
              placeholder="Please select"
              options={options}
            />
          </Form.Item>
        </Col>
      </Row>

      <Form.Item>
        <Row gutter={[20, 20]}>
          <Col>
            <Button type="primary" htmlType="submit">
              Сохранить изменения
            </Button>
          </Col>
          <Col>
            <Button htmlType="button" onClick={onReset}>
              Отмена
            </Button>
          </Col>
        </Row>
      </Form.Item>
    </Form>
  );
};