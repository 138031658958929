import moment from "moment"
import { emailValid } from "."
import { AppDispatch } from "../store/store";
import { IDataInJsonFormat } from "../types/types";
import {validateInn} from "./Validates";


export const dataInJsonFormat = (values: IDataInJsonFormat, _token: string | number | null, fiasAddress: string, fiasId: string, startDate: string) => {
  const year = moment(startDate, 'DD.MM.YYYY').format('YYYY')
  const month = moment(startDate, 'DD.MM.YYYY').format('MM')
  const day = moment(startDate, 'DD.MM.YYYY').format('DD')

  return JSON.stringify({
    token: _token,
    email: values.email?.toLowerCase(),
    login: values.email,
    sex: values.sex,
    user_info: {
      geo: {fias_addr: fiasAddress, fias_id: fiasId},
      work: {place: values.place, industry: values.industry, position: values.position, inn: values.inn},
      birthday: {
        year: Number(year),
        month: Number(month),
        day: Number(day),
      },
      docs: {snils: values.snils, id_doc: values.idDoc, id_doc_name: values.idDocName},
      passport: {name: values.name, sur_name: values.sur_name, patronymic: values.patronymic}
    }
  })
}

export const checkChange = async (e: any, setCheckNick: (is: boolean) => void, form: any) => {
  setCheckNick(e.target.checked)
  if (e.target.checked) {
    form.setFieldsValue({patronymic: ''})
    await form.validateFields(['patronymic']);
  }

  localStorage.setItem('check', e.target.checked)
}

export const getEmailValue = (e: React.ChangeEvent<HTMLInputElement>, setConfirmEmailDisabled?: any) => {
  if(e.target.value.match(emailValid)){
    setConfirmEmailDisabled(true)
  }else{
    setConfirmEmailDisabled(false)
  }
}

export const clickOutsideClose = (e: any, setIsOptionsOpen: (is: boolean) => void) => {
  if (e.relatedTarget?.name !== 'searchButton') {
    setIsOptionsOpen(false)
  } else {
    e.target.focus()
  }
}

export const onSearchAddressSelect = (value: string, setQueryState: any, setIsAddressChose: any) => {
  setIsAddressChose(false)
  if (value) {
    setQueryState(value)
  } else {
    setQueryState(null)
  }
}

export const handleKeyDown = (e: any, onSubmitAddressSelect: any) => {
  if (e.key === 'Enter') {
    onSubmitAddressSelect(e);
  }
}

export const onKeyDownDatePicker = (e: any) => {
  if (e.code === 'Enter') {
    e.preventDefault()
  }
};

export const getInnValue = (value: string, dispatch: AppDispatch, setInnValue: any) => {
  dispatch(validateInn(Number(value)))
  setInnValue(value)
}

export const handleKeyDownInn = (e: any, onSubmitInnSelect: any) => {
  if(e.key === 'Enter'){
    onSubmitInnSelect(e)
  }
}

export const clickOutsideInnClose = (e: any, setIsOpenInn: (is: boolean) => void) => {
  if (e.relatedTarget?.name !== 'searchButton') {
    setIsOpenInn(false)
  } else {
    e.target.focus()
  }
}