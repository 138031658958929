import React, { FC } from 'react';
import { Button, Col, Form, Row, Select } from 'antd';
import InputPforile from '../UserEditModal/InputProfile';
import { IOnFinishValues } from '../../../types/types';
import { useAppDispatch } from '../../../hooks/useAppDispatch';
import { addOauthApp } from '../../../store/adminAction';
import { options } from '../../../utils/const';

export const OauthAddModal: FC<{ setIsOauthAddModalOpen: (b: boolean) => void}> = ({ setIsOauthAddModalOpen }) => {
  const dispatch = useAppDispatch();
  const [form] = Form.useForm();
  const superAdmin = localStorage.getItem('superAdmin');

  const onReset = () => {
    form.resetFields();
    setIsOauthAddModalOpen(false)
  };

  const onFinish = (values: IOnFinishValues): void => {
    if (superAdmin) {
      const data = {
        superAdmin: JSON.parse(superAdmin),
        values
      }
      dispatch(addOauthApp(data))
      setIsOauthAddModalOpen(false)
    }
  };

  return (
    <Form
      form={form}
      layout='vertical'
      onFinish={onFinish}
    >
      <Row gutter={[20, 20]}>
        <Col span={12}>
          <Form.Item
            hasFeedback
            rules={[{required: true, message: "Введите домен"}]}
            label="Домен"
            name="domain"
          >
            <InputPforile type="text" placeholder="Введите домен" />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            hasFeedback
            rules={[{required: true, message: "Введите имя"},]}
            label="Имя"
            name="name"
          >
            <InputPforile type="text" placeholder="Введите имя" />
          </Form.Item>
        </Col>
      </Row>

      <Row>
        <Col span={24}>
          <Form.Item
            label="Выберите поля"
            name="requested_fields"
          >
            <Select
              mode="multiple"
              allowClear
              style={{width: '100%'}}
              placeholder="Выберите поля"
              options={options}
            />
          </Form.Item>
        </Col>
      </Row>

      <Form.Item>
        <Row gutter={[20, 20]}>
          <Col>
            <Button type="primary" htmlType="submit">
              Сохранить изменения
            </Button>
          </Col>
          <Col>
            <Button htmlType="button" onClick={onReset}>
              Отмена
            </Button>
          </Col>
        </Row>
      </Form.Item>
    </Form>
  );
};