import React from 'react';
import {BrowserRouter} from 'react-router-dom';
import {Provider} from "react-redux";
import {setupStore} from './store/store';
import {createRoot} from 'react-dom/client'
import App from './App';
import './index.css';

const store = setupStore();

const element: HTMLElement | null = document.getElementById('root')

if (element) {
    const root = createRoot(element)

    root.render(
        <BrowserRouter>
            <Provider store={store}>
                <App/>
            </Provider>
        </BrowserRouter>,
    )
}


