import { SelectProps } from 'antd';

export const BASE_BUCKET = 'basebucket'

export const logTypes = ['error', 'info', 'fatal'];

export const logServices = [
  'assmnt.gateway', 
  'auth', 
  'courses', 
  'assessment', 
  'assmt.engine', 
  'messenger', 
  'procto_ai', 
  'notifier', 
  'puller', 
  'support', 
  'face.collector'
];

export const filterNames = {
  service: 'Сервис',
  date: 'Время события',
  type: 'Тип'
}

export const initItem = {
  id: '',
  translateKey: '',
  renderConditionString: '',
  order: 0,
  name: '',
  title: '',
  sets: [],
  icon: '',
  route: '',
  menu: []
}

export const bl_payload = {
  offset: 0,
  page: 1,
  limit: 10
}

export const service_actions = {
  ban: 'Забанить',
  unban: 'Разбанить',
  done: 'Готово',
  cancel: 'Отменить'
}

export const options: SelectProps['options'] = [
  {label: 'фамилия', value: 'sur_name'},
  {label: 'Имя', value: 'name'},
  {label: 'Пол', value: 'sex'},
  {label: 'Отчество', value: 'patronymic'},
  {label: 'Дата рождения', value: 'birthday'},
  {label: 'Место работы', value: 'place'},
  {label: 'Телефон', value: 'phone'},
  {label: 'Почта', value: 'email'},
  {label: 'Адрес', value: 'address'},
  {label: 'СНИЛС', value: 'snils'},
  {label: 'Дополнительный СНИЛС', value: 'snils_dop'},
  {label: 'Номер документа', value: 'idDoc'},
  {label: 'Наименование документа', value: 'idDocName'},
  {label: 'ИНН', value: 'inn'},
  {label: 'Роль', value: 'role'},
];