export const PaperClipIcon = () => (
    <svg width="33" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_652_696)">
        <path d="M31.1455 11.6541C30.6234 11.1348 29.7792 11.1371 29.2599 11.6592L13.2315 27.7742C11.152 29.8537 7.7746 29.8537 5.69124 27.7714C3.60944 25.6885 3.60944 22.3112 5.69149 20.2291L22.1943 3.63959C23.4926 2.34137 25.6033 2.34137 26.9054 3.6424C28.207 4.94406 28.207 7.05455 26.9051 8.35658L13.234 22.0275C13.2331 22.0284 13.2324 22.0294 13.2315 22.0303C12.7107 22.5482 11.8689 22.5475 11.3492 22.0278C10.8285 21.5071 10.8285 20.6633 11.3492 20.1427L17.9481 13.5424C18.4687 13.0216 18.4687 12.1774 17.9479 11.6567C17.4271 11.1361 16.5829 11.1361 16.0622 11.6569L9.46339 18.2571C7.90142 19.819 7.90142 22.3513 9.46352 23.9134C11.0256 25.4754 13.5579 25.4754 15.12 23.9134C15.1218 23.9116 15.1232 23.9096 15.125 23.9078L28.7907 10.2421C31.1341 7.89869 31.1341 4.09973 28.7907 1.7563C26.4469 -0.585758 22.6482 -0.585758 20.3061 1.7563L3.80332 18.3459C0.682373 21.4669 0.682372 26.5323 3.80557 29.6571C6.93083 32.7807 11.9963 32.7807 15.1197 29.6573L31.1507 13.5398C31.6699 13.0176 31.6676 12.1734 31.1455 11.6541Z" fill="#E4E7F2"/>
      </g>
      <defs>
        <clipPath id="clip0_652_696">
          <rect width="32" height="32" fill="white" transform="translate(0.5)"/>
        </clipPath>
      </defs>
    </svg>
  );