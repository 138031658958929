import React, { useEffect, useState } from "react";
import { getLogs } from "../../store/adminAction";
import styles from "./Logs.module.css";
import { Empty, Input, Modal, Select } from "antd";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { SearchIcon } from "../../assets/icons/SearchIcon";
import Icon from "@ant-design/icons";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import Loader from "../Loader/Loader";
import { Filter } from "../Filter/Filter";
import { IFilters, IGetLogs } from "../../types/types";
import { filterNames } from "../../utils/const";

const Logs = () => {
  const [pagination, setPagination] = useState<number>(10);
  const [counter, setCounter] = useState<number>(1);
  const [countPagination, setCountPagination] = useState<number>(1);
  const [fieldsInput, setFieldsInput] = useState<string>("");
  const [contentModal, setContentModal] = useState<string>("");
  const [visible, setVisible] = useState<boolean>(false);
  const [filters, setFilters] = useState<IFilters>({});
  const { logs, isLoading } = useTypedSelector((state) => state.adminReducer);
  const Option = Select.Option;
  const dispatch = useAppDispatch();

  const superAdmin = JSON.parse(localStorage.getItem("superAdmin") || "{}");

  const data = {
    superAdmin,
    pagination: { limit: Number(pagination), page: 1 },
    query: fieldsInput,
  };

  const _getLogs = (filter?: string, title?: string) => {
    if (typeof filter === 'undefined') {
      dispatch(getLogs(data))
    } else if (title === filterNames.service) {
      setFilters({
        service: filter,
        date: filters.date,
        type: filters.type
      })
    } else if (title === filterNames.date) {
      setFilters({
        service: filters.service,
        date: filter,
        type: filters.type
      })
    } else if (title === filterNames.type) {
      setFilters({
        service: filters.service,
        date: filters.date,
        type: filter
      })
    }
  };

  useEffect(() => {
    _getLogs()
  }, []);

  useEffect(() => {
    dispatch(getLogs({
      ...data,
      query_service: filters.service,
      query_date: filters.date,
      query_type: filters.type,
    }))
  }, [filters])

  const checkFilters = (data: IGetLogs): void => {
    if (Object.keys(filters).length === 0) {
      dispatch(getLogs(data))
    } else {
      dispatch(getLogs({
        ...data,
        query_service: filters.service,
        query_date: filters.date,
        query_type: filters.type,
      }))
    }
  }

  const changePageSizeForPagination = (e: string) => {
    const data = {
      superAdmin,
      pagination: { limit: Number(e), page: 1 },
      query: fieldsInput,
    };

    checkFilters(data)
    setPagination(Number(e));
    setCounter(1);
    setCountPagination(1);
  };

  const arrowRight = () => {
    setCounter(counter + 1);
    setCountPagination(countPagination + Number(pagination));
    const data = {
      superAdmin,
      pagination: { limit: Number(pagination), page: counter + 1 },
      query: fieldsInput,
    };

    checkFilters(data)
  };

  const arrowLeft = () => {
    if (counter > 1) {
      setCounter(counter - 1);
      setCountPagination(countPagination - Number(pagination));
      const data = {
        superAdmin,
        pagination: { limit: Number(pagination), page: counter - 1 },
        query: fieldsInput,
      };

      checkFilters(data)
    }
  };

  const dateMask = (date: any) => {
    if (date[0] === "2") {
      const mask = /[\-]/g;
      return date.substr(0, 10).replace(mask, ".");
    } else {
      return null;
    }
  };

  const searchUsers = (e: React.ChangeEvent<HTMLInputElement>) => {
    const data = {
      superAdmin,
      pagination: { limit: Number(pagination), page: 1 },
      query: e.target.value,
    };

    checkFilters(data)
    setFieldsInput(e.target.value);
    setCounter(1);
    setCountPagination(1);
  };

  const closeDataModal = () => {
    setVisible(false);
  };

  const openDataModal = (data: string) => {
    const prettyData = JSON.stringify(JSON.parse(data), null, 3)
    setVisible(true);
    setContentModal(prettyData);
  };

  return (
    <div className={styles._body}>
      <div className={styles._bodyHeader}>
        <div className={styles._bodyHeaderChildBlock}>
          <div className={styles._listUser}>Список логов</div>
        </div>
      </div>

      <Input
        className={styles._input}
        placeholder={" Начните вводить текст для поиска"}
        prefix={<Icon component={SearchIcon} className={styles.prefix} />}
        onPressEnter={searchUsers}
      />

      <Modal
        visible={visible}
        onCancel={closeDataModal}
        className={styles.EditModalProfile}
        title={false}
        footer={false}
        width={1000}
      >
        <div>
          <pre>{contentModal}</pre>
        </div>
      </Modal>


      <div className={styles._tableBlock}>
        <table className={styles._table}>
          <thead>
            <tr className={styles.tableThead}>
              <th>
                Время события
                <Filter title={filterNames.date} getLogsFilterCallback={_getLogs} />
              </th>
              <th>Данные</th>
              <th>Сообщение</th>
              <th>
                Сервис
                <Filter title={filterNames.service} getLogsFilterCallback={_getLogs} />
              </th>
              <th>Супер пользователь</th>
              <th>Саппорт</th>
              <th>
                Тип
                <Filter title={filterNames.type} getLogsFilterCallback={_getLogs} />
              </th>
            </tr>
          </thead>

          <tbody>
            {
              isLoading ? <Loader /> : (
                <>
                  {logs.length ? (
                    logs.map((item) => (
                      <tr
                        key={item.event_date_time}
                        className={styles.tableTbody}
                        onClick={() => openDataModal(item.data)}
                      >
                        <td className={styles.avatarBlock}>{`${dateMask(
                          item.event_date_time
                        )} ${item.event_date_time.substring(11, 16)}`}</td>
                        <td>{item.data.substring(0, 90)}</td>
                        <td className={styles.passportBlock}>
                          {item.message.substring(0, 90)}
                        </td>
                        <td>{item.service}</td>
                        <td>{item.superuser}</td>
                        <td>{item.support}</td>
                        <td>{item.type}</td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan={10}>
                        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                      </td>
                    </tr>
                  )}
                </>
              )
            }
          </tbody>
        </table>
      </div>
      {
        isLoading ? "" : (
          <div className={styles.tableFooter}>
            <div className={styles.tableFooterChildBlock}>
              <div className={styles.select}>
                На странице по :
                <Select defaultValue={"10"} onChange={changePageSizeForPagination}>
                  <Option value="5">5</Option>
                  <Option value="10">10</Option>
                  <Option value="20">20</Option>
                  <Option value="50">50</Option>
                  <Option value="100">100</Option>
                </Select>
              </div>
              <div className={styles.arrow}>
                <p>
                  <i className={styles.arrow_left} onClick={arrowLeft} />
                </p>
                <p>
                  <i className={styles.arrow_right} onClick={arrowRight} />
                </p>
              </div>
            </div>
          </div>
        )
      }
    </div>
  );
};

export default Logs;
