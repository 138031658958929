import React, {ChangeEvent, FC, useEffect, useLayoutEffect, useState} from 'react';
import {Button, Col, Form, Input, Radio, Row, Select} from "antd";
import DatePicker from "react-datepicker";
import styles from './UserEditModule.module.css'
import {calendarMask, emailValid, fioValid, phoneValid} from "../../../utils";
import moment from "moment";
import InputPforile from "./InputProfile";
import AvatarComponent from './AvatarComponent';
import MaskedInput from "antd-mask-input";
import {getPhoneFunc} from "../../../utils/PhoneFilter";
import Icon from "@ant-design/icons";
import ru from 'date-fns/locale/ru';
import 'react-datepicker/dist/react-datepicker.css'
import MaskedTextInput from "react-text-mask";
import {CheckCircleIcon} from "../../../assets/icons/CheckCircleIcon";
import {CloseCircleIcon} from "../../../assets/icons/CloseCircleIcon";
import {
   clickOutsideClose, clickOutsideInnClose,
   dataInJsonFormat, getEmailValue, getInnValue,
   handleKeyDown, handleKeyDownInn,
   onKeyDownDatePicker,
   onSearchAddressSelect
} from "../../../utils/ProfileRegistration";
import {
   validateSnils,
   setUsers,
   getUserGeoData,
   getUserInnData,
   addRole,
   deleteRole
} from '../../../store/adminAction';
import CheckableTag from 'antd/lib/tag/CheckableTag';
import {Plus} from '../../../assets/icons/Plus';
import {Correct} from '../../../assets/icons/Correct';
import {useTypedSelector} from '../../../hooks/useTypedSelector';
import { IAddressSelectValue, IDataInJsonFormat, IUserEditModule } from '../../../types/types';
import {useAppDispatch} from '../../../hooks/useAppDispatch';


const UserEditModule: FC<IUserEditModule> = ({setIsEditModule, superAdmin, id, modal}) => {
   const [checkCitizen, setCheckCitizen] = useState<boolean>(false);
   const [citizenRf, setCitizenRf] = useState<boolean>(false)
   const [militaryGraduate, setMilitaryGraduate] = useState<boolean>(false)

   const {oneUser, _token, errors, result_valid_inn, inn, address} = useTypedSelector(state => state.adminReducer);

   const [form] = Form.useForm();
   const dispatch = useAppDispatch()

   const [HOCMasked, setHOCMasked] = useState<any>({Input: InputPforile, focus: false})
   const {Option} = Select;

   const [queryState, setQueryState] = useState<null | string>();
   const [isOptionsOpen, setIsOptionsOpen] = useState<boolean>(false)
   const [fiasId, setFiasId] = useState<string>(oneUser.user_info.geo.fias_id)
   const [fiasAddress, setFiasAddress] = useState<string>(oneUser.user_info.geo.fias_addr)
   const year = moment(oneUser.user_info?.birthday?.year ? oneUser.user_info?.birthday?.year : '2020', 'YYYY').format('YYYY')
   const month = moment(oneUser.user_info?.birthday?.month ? oneUser.user_info?.birthday?.month : '04', 'MM').format('MM')
   const valuesDay = oneUser.user_info?.birthday?.day
   const day = valuesDay === 31 ? valuesDay : moment(oneUser.user_info?.birthday?.day ? oneUser.user_info?.birthday?.day : '24', 'DD').format('DD')
   let birthdayWithBackEnd: any = moment(`${day}.${month}.${year}`, 'DD.MM.YYYY')

   const [focus, setFocus] = useState<boolean>(false)
   const [isAddressChose, setIsAddressChose] = useState<boolean>(true);

   const [innValue, setInnValue] = useState<string>('')
   const [isOpenInn, setIsOpenInn] = useState<boolean>(false)
   const [startDate, setStartDate] = useState<any>(birthdayWithBackEnd._d);

   if (birthdayWithBackEnd._isValid !== true) {
      birthdayWithBackEnd = null
   }

   const _setUserData = () => {
      try {
         form.setFieldsValue({
            sur_name: oneUser.user_info.passport.sur_name,
            name: oneUser.user_info.passport.name,
            sex: oneUser.sex,
            patronymic: oneUser.user_info.passport.patronymic,
            birthday: birthdayWithBackEnd,
            place: oneUser.user_info.work.place,
            position: oneUser.user_info.work.position,
            industry: oneUser.user_info.work.industry,
            phone: getPhoneFunc(oneUser.phone),
            email: oneUser.email,
            address: oneUser.user_info.geo.fias_addr,
            snils: oneUser.user_info.docs.snils,
            snils_dop: oneUser.user_info.docs.snils_dop,
            idDoc: oneUser.user_info.docs.id_doc,
            idDocName: oneUser.user_info.docs.id_doc_name,
            inn: oneUser.user_info.work.inn,
            role: '',
         });
      } catch (e) {
         console.log('уведомление')
      }
   }

   useLayoutEffect(() => {
      _setUserData()
      setStartDate(birthdayWithBackEnd._d)
      setQueryState(form.getFieldValue(['address']));
      setInnValue(form.getFieldValue(['inn']));
      if (form.getFieldValue(["idDocName"])) {
         setCheckCitizen(true);
      }
      if (form.getFieldValue(['snils_dop'])) {
         setMilitaryGraduate(true)
      }
      if (form.getFieldValue(['snlis'])) {
         setCitizenRf(true)
      }
   }, [oneUser, modal]);

   useEffect(() => {
      if (!checkCitizen && !militaryGraduate) {
         setCitizenRf(true)
      } else if (checkCitizen) {
         setCitizenRf(false)
         setMilitaryGraduate(false)
      } else if (militaryGraduate) {
         setCitizenRf(false)
         setCheckCitizen(false)
      }
   }, [citizenRf, checkCitizen, militaryGraduate])

   const onFinish = (values: IDataInJsonFormat) => {
      if (startDate) {
         const dataInJson = dataInJsonFormat(values, _token, fiasAddress, fiasId, startDate)
         dispatch(setUsers({data: dataInJson, setIsEditModule}))
      }
   };

   const onAddRole = () => {
      dispatch(addRole({superAdmin, id, role: form.getFieldValue(['role']), service: selectedTags}));
   }

   const onDeleteRole = () => {
      dispatch(deleteRole({superAdmin, id, role: form.getFieldValue(['role']), service: selectedTags}));
   };

   // SELECT ROLE
   const [selectedTags, setSelectedTags] = useState<any[]>([]);
   const tagsData: string[] = ['assessment', 'builder', 'edp', 'apps', 'courses', 'shareplace', 'moderation', 'robocode', 'oneid', 'support', 'portal'];

   const handleChange = (tag: string, checked: boolean) => {
      const nextSelectedTags: string[] = checked
        ? [...selectedTags, tag]
        : selectedTags.filter((t) => t !== tag);
      setSelectedTags(nextSelectedTags);
   };

   const onChangeAddressSelect = (value: string, val: IAddressSelectValue | IAddressSelectValue[]) => {
      const fiasId = Array.isArray(val) ? val[0].fias_id : val.fias_id
      if (value) {
         setIsAddressChose(true)
         form.setFieldsValue({address: value});
         setQueryState(value)
         setFiasId(fiasId)
         setFiasAddress(value)
         setIsOptionsOpen(false)
      }
   }

   const onAddressClear = () => {
      setFiasId('')
      setFiasAddress('')
      setQueryState(null)
   }

   const onSubmitAddressSelect = (e: any) => {
      e.preventDefault()
      if (queryState) {
         dispatch(getUserGeoData({token: _token, query: queryState}));
      }
      setIsOptionsOpen(true)
   }

   const getSnilsValue = (e: React.ChangeEvent<HTMLInputElement>) => {
      setHOCMasked({Input: MaskedInput, focus: true})
      dispatch(validateSnils(e.target.value))
   }

   const onSubmitInnSelect = (e: any) => {
      e.preventDefault()
      if (innValue && result_valid_inn) {
         dispatch(getUserInnData({token: _token, query: innValue}));
      }
      setIsOpenInn(true)
   }

   const onChangeInnSelect = (value: string) => {
      if (value) {
         form.setFieldsValue({inn: innValue});
         form.setFieldsValue({place: value});
         setIsOpenInn(false)
      }
   }

   const checkCitizenChange = (e: React.ChangeEvent<HTMLInputElement>, setCheckCitizen: (is: boolean) => void, form: any) => {
      setCheckCitizen(true)
      setCitizenRf(false)
      setMilitaryGraduate(false)
      if (e.target.checked) {
         form.setFieldsValue({snils: ''})
         form.setFieldsValue({snils_dop: ''})
      }
   }

   const checkMilitaryGraduateChange = (e: ChangeEvent<HTMLInputElement>) => {
      setMilitaryGraduate(true)
      setCitizenRf(false)
      setCheckCitizen(false)
      if (e.target.checked) {
         form.setFieldsValue({snils: ''})
         form.setFieldsValue({idDoc: ''})
         form.setFieldsValue({idDocName: ''})
      }
   }

   const checkCitizenRf = (e: ChangeEvent<HTMLInputElement>) => {
      setCitizenRf(true)
      setMilitaryGraduate(false)
      setCheckCitizen(false)
      if (e.target.checked) {
         form.setFieldsValue({idDoc: ''})
         form.setFieldsValue({idDocName: ''})
         form.setFieldsValue({snils_dop: ''})
      }
   }

   return (
      <div className={styles.profile__wrapper}>
         <AvatarComponent _id={oneUser.id} avatar={oneUser.avatar}/>

         <div className={styles._body}>
            <h2 className={styles._title}>
               Общие данные
            </h2>

            <p className={styles._description}>
               Здесь вы можете изменить личные данные
            </p>

            <Form
               form={form}
               layout='vertical'
               onFinish={onFinish}
            >
               <Row className={`${styles.marTop} ${styles.flexWidth}`}>
                  <Col>
                     <Form.Item
                        hasFeedback
                        rules={[
                           {message: 'Вы можете использовать символы и кириллицу', pattern: fioValid},
                           {required: true, message: 'Введите фамилию'},
                        ]}
                        label='Фамилия'
                        name='sur_name'>
                        <InputPforile type='text' placeholder='Введите фамилию'/>
                     </Form.Item>
                  </Col>
                  <Col>
                     <Form.Item
                        label='Имя' hasFeedback
                        rules={[
                           {message: 'Вы можете использовать символы и кириллицу', pattern: fioValid},
                           {required: true, message: 'Введите имя'}
                        ]}
                        name='name'>
                        <InputPforile type='text' placeholder='Введите имя'/>
                     </Form.Item>
                  </Col>
               </Row>

               <Row className={`${styles.flexWidth}`}>
                  <Col>
                     <Form.Item
                        className={styles._patronymic}
                        label='Отчество'
                        rules={[
                           {message: 'Вы можете использовать символы и кириллицу', pattern: fioValid},
                           {required: false, message: 'Введите отчество'}
                        ]}
                        hasFeedback
                        name='patronymic'
                     >
                        <InputPforile type='text' placeholder='Введите отчество'/>
                     </Form.Item>
                  </Col>

                  <Col>
                     <Form.Item
                        hasFeedback
                        label='Дата рождения'
                        rules={[
                           {required: true, message: 'Введите дату рождения'}
                        ]}
                        name='birthday'
                     >
                        <div className={styles.datePickerInputBlock}>
                           <DatePicker
                              placeholderText='Введите дату рождения'
                              locale={ru}
                              dateFormat='dd.MM.yyyy'
                              className={`${styles.datePickerInput} ${!startDate ? styles.errorLine : styles.defaultLine}`}
                              selected={startDate}
                              onChange={(date: any) => setStartDate(date)}
                              onKeyDown={onKeyDownDatePicker}
                              isClearable={focus}
                              onFocus={() => setFocus(true)}
                              onCalendarClose={() => setTimeout(() => setFocus(false), 160)}
                              maxDate={new Date()}
                              customInput={
                                 <MaskedTextInput
                                    id='birthday'
                                    type="text"
                                    mask={calendarMask}
                                 />
                              }
                           />
                        </div>
                        <span className={styles.errorText}>
                  {!startDate && 'Выберите дату рождения'}
                </span>
                        {
                           startDate
                              ? <Icon component={CheckCircleIcon} className={styles.checkCircleIcon}/>
                              : <Icon component={CloseCircleIcon} className={styles.closeCircleIcon}/>
                        }
                     </Form.Item>
                  </Col>
               </Row>

               <Row>
                  <Col>
                     <Form.Item
                        label="Пол"
                        name="sex"
                        rules={[
                           {required: true, message: 'Выберите пол'}
                        ]}
                     >
                        <Radio.Group name="sex">
                           <Radio value="male"> Мужской </Radio>
                           <Radio value="female"> Женский </Radio>
                        </Radio.Group>
                     </Form.Item>
                  </Col>
               </Row>

               <div className={styles._line}/>

               <Row>
                  <Col span={30} className={styles.hocMaskedError}>
                     <div className={styles.checkboxes}>
                        <div className={styles._checkBox}>
                           <Radio
                              checked={citizenRf}
                              onChange={(e: any) => checkCitizenRf(e)}
                           >
                              Гражданин РФ
                           </Radio>
                        </div>
                        <div className={styles._checkBox}>
                           <Radio
                              checked={checkCitizen}
                              onChange={(e: any) => checkCitizenChange(e, setCheckCitizen, form)}
                           >
                              Не являюсь гражданином РФ
                           </Radio>
                        </div>
                        <div className={styles._checkBox}>
                           <Radio
                              checked={militaryGraduate}
                              onChange={(e: any) => checkMilitaryGraduateChange(e)}
                           >
                              Студент военного ВУЗа
                           </Radio>
                        </div>
                     </div>
                  </Col>

               </Row>


               {citizenRf && (
                  <Row>
                     <Col span={12}>
                        <Form.Item
                           hasFeedback
                           rules={[{required: !checkCitizen, message: 'ХХХ-ХХХ-ХХХ YY'},
                              {
                                 validator(_, value) {
                                    if (value && errors) {
                                       return Promise.reject(errors);
                                    } else if (value) {
                                       return Promise.resolve('okay')
                                    } else {
                                       return Promise.resolve('okay')
                                    }
                                 }
                              }]}
                           label='СНИЛС'
                           name='snils'
                        >
                           <HOCMasked.Input
                              mask={"000-000-000 00"}
                              autoFocus={HOCMasked.focus}
                              size="20"
                              allowClear
                              type="text"
                              placeholder="ХХХ-ХХХ-ХХХ YY"
                              disabled={checkCitizen}
                              onChange={getSnilsValue}
                              onFocus={getSnilsValue}
                           />
                        </Form.Item>
                     </Col>
                  </Row>
               )}

               {checkCitizen && (
                  <Row>
                     <Col span={24}>
                        <Form.Item
                           hasFeedback
                           rules={[
                              {required: true, message: 'Введите наименование документа'},
                              {max: 40, message: "Значение должно быть меньше 40 символов"},
                           ]}
                           label='Наименование документа'
                           name='idDocName'
                        >
                           <Input type='text' placeholder='Введите имя документа'/>
                        </Form.Item>
                     </Col>
                     <Col span={24}>
                        <Form.Item
                           hasFeedback
                           rules={[
                              {required: true, message: 'Введите номер документа'},
                              {max: 40, message: "Значение должно быть меньше 40 символов"},
                           ]}
                           label='Номер документа'
                           name='idDoc'
                        >
                           <Input name='idDoc' type='text' placeholder='Введите номер документа'/>
                        </Form.Item>
                     </Col>
                  </Row>
               )}

               {militaryGraduate && (
                  <Row>
                     <Col span={12}>
                        <Form.Item
                           hasFeedback
                           rules={[
                              {required: true, message: 'Введите дополнительный СНИЛС'},
                           ]}
                           label="Дополнительный СНИЛС"
                           name="snils_dop"
                        >
                           <MaskedInput
                              name="snils_dop"
                              mask={"ВВВ-000-000 00"}
                              size="middle"
                              allowClear
                              type="text"
                              placeholder="ВВВ-ХХХ-ХХХ YY"
                           />
                        </Form.Item>
                     </Col>
                  </Row>
               )}

               <div className={styles._line}/>

               <Row>
                  <Col className={styles.inputBlock}>
                     <Form.Item
                        className={styles.addressForm}
                        hasFeedback
                        label='Адрес'
                        rules={[
                           {type: "string", required: false, message: 'Выберите адрес'},
                           {
                              validator() {
                                 if (isAddressChose && !isOptionsOpen) {
                                    return Promise.resolve(true)
                                 } else {
                                    return Promise.reject('Выберите адрес из списка');
                                 }
                              }
                           }
                        ]}
                        name='address'
                     >
                        <Select
                           showSearch
                           allowClear
                           className={styles.addressSelect}
                           value={queryState}
                           defaultActiveFirstOption={false}
                           showArrow={false}
                           filterOption={false}
                           placeholder="Введите данные"
                           onSearch={(e) => onSearchAddressSelect(e, setQueryState, setIsAddressChose)}
                           onChange={onChangeAddressSelect}
                           open={isOptionsOpen}
                           onKeyDown={(e) => handleKeyDown(e, onSubmitAddressSelect)}
                           onBlur={(e) => clickOutsideClose(e, setIsOptionsOpen)}
                           onClear={onAddressClear}
                           notFoundContent="Необходимо ввести адрес"
                        >
                           {address.length && address.map((item, index) => (
                              <Option
                                 key={index + 1}
                                 value={item.value}
                                 fias_id={item.data.fias_id}
                              >
                                 {item.value}
                              </Option>
                           ))
                           }
                        </Select>
                        <Button
                           name='searchButton'
                           className={styles.btnSearchAddress}
                           type='primary'
                           onClick={onSubmitAddressSelect}
                        >
                           Поиск
                        </Button>
                     </Form.Item>
                  </Col>
               </Row>

               <Row className={styles.flexWidth} align='middle'>
                  <Col className={styles.inputBlock}>
                     <Form.Item
                        label='Телефон'
                        name={'phone'}
                        rules={[{required: true, min: 11, message: 'пример +7(000)000-00-00'},
                           {
                              validator: (_, value) =>
                                 value &&
                                 phoneValid.test(value)
                                    ? Promise.resolve('okay')
                                    : Promise.reject(''),
                           },
                        ]}
                        hasFeedback
                     >
                        <InputPforile
                           type="text"
                           placeholder="+7(xxx)xxx-xx-xx"
                        />
                     </Form.Item>
                  </Col>
               </Row>

               <Row className={styles.flexWidth} align='middle'>
                  <Col>
                     <Form.Item
                        rules={[{type: 'email', required: true, message: 'test@mail.ru', pattern: emailValid}]}
                        hasFeedback
                        label='E-mail'
                        name='email'
                     >
                        <Input
                           onChange={(e) => getEmailValue(e)}
                           type='text'
                           placeholder='Введите e-mail'
                        />
                     </Form.Item>
                  </Col>
               </Row>

               <div className={styles._line}/>

               <Row className={styles.innBlock}>
                  <Col span={24} className={styles.inputBlock}>
                     <Form.Item
                        hasFeedback
                        className={`${styles.addressForm}`}
                        label='Роль пользователя'
                        name='role'
                     >
                        <Select
                           showSearch
                           allowClear
                           className={`${styles.addressSelect}`}
                           placeholder="Выберите роль"
                           notFoundContent="Необходимо выбрать роль"
                        >
                           <Option
                              key={'moderator'}
                              value={'moderator'}
                           >
                              Модератор
                           </Option>
                           <Option
                              key={'admin'}
                              value={'admin'}
                           >
                              Администратор
                           </Option>
                           <Option
                              key={'support'}
                              value={'support'}
                           >
                              Саппорт
                           </Option>
                           <Option
                              key={'subsupport'}
                              value={'subsupport'}
                           >
                              Сабсаппорт
                           </Option>
                        </Select>
                     </Form.Item>
                  </Col>

                  <Col span={16}>
                     <div className={styles.checkable_block}
                     >
                        {tagsData.map((tag: string) => (
                           <CheckableTag
                              key={tag}
                              checked={selectedTags.indexOf(tag) > -1}
                              onChange={(checked) => handleChange(tag, checked)}
                           >
                              {tag}
                              {selectedTags.indexOf(tag) > -1 ? <Correct/> : <Plus/>}
                           </CheckableTag>
                        ))}
                     </div>
                  </Col>

                  <Col span={24}>
                     <Button
                       type='primary'
                       onClick={onAddRole}
                     >
                        Добавить роль
                     </Button>
                     <Button
                       type='default'
                       onClick={onDeleteRole}
                       className={styles.btnDeleteRole}
                     >
                        Удалить роль
                     </Button>
                  </Col>
               </Row>


               <div className={styles._line}/>

               <Row className={styles.innBlock}>
                  <Col className={styles.inputBlock}>
                     <Form.Item
                        hasFeedback
                        className={`${styles.addressForm}`}
                        label='ИНН организации'
                        name='inn'
                     >
                        <Select
                           showSearch
                           allowClear
                           className={`${styles.addressSelect}`}
                           value={innValue}
                           defaultActiveFirstOption={false}
                           showArrow={false}
                           filterOption={false}
                           open={isOpenInn}
                           placeholder="Введите ИНН организации"
                           onSearch={(e) => getInnValue(e, dispatch, setInnValue)}
                           onKeyDown={(e) => handleKeyDownInn(e, onSubmitInnSelect)}
                           onBlur={(e) => clickOutsideInnClose(e, setIsOpenInn)}
                           onChange={onChangeInnSelect}
                           onClear={() => setInnValue('')}
                           notFoundContent="Необходимо ввести ИНН организации"
                        >
                           {inn.length && inn.map((item, index) => (
                              <Option
                                 key={index + 1}
                                 value={item.value}
                              >
                                 {item.value}
                              </Option>
                           ))
                           }
                        </Select>
                        <Button
                           name='searchButton'
                           className={styles.btnSearchAddress}
                           onClick={onSubmitInnSelect}
                           type='primary'
                        >
                           Поиск
                        </Button>
                     </Form.Item>
                  </Col>
               </Row>

               <Row>
                  <Col span={24}>
                     <Form.Item
                        label='Место работы'
                        name='place'
                        rules={[{type: "string", required: false, message: 'Место работы'}]}>
                        <InputPforile placeholder='Введите данные'/>
                     </Form.Item>
                  </Col>
               </Row>

               <Row>
                  <Col className={`${styles.flexWidthHalf} ${styles.position}`}>
                     <Form.Item label='Должность' name='position'>
                        <InputPforile type='text' placeholder='Введите данные'/>
                     </Form.Item>
                  </Col>
               </Row>

               <div className={styles._line}/>

               <div className={styles.actions__btn}>
                  <Form.Item>
                     <Button type="primary" htmlType="submit">
                        Сохранить изменения
                     </Button>
                  </Form.Item>
                  <Form.Item>
                     <Button
                        type="link"
                        htmlType="submit"
                        className={styles.actions__btn__right}
                        onClick={() => setIsEditModule(false)}
                     >
                        Отмена
                     </Button>
                  </Form.Item>
               </div>
            </Form>
         </div>
      </div>
   );
};
export default UserEditModule;
